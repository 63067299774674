import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { changeLoginKey, changeMembercode } from "../Action/memberAction";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage:
      "linear-gradient(to right, var(--primaryGreen, #005c49), rgb(10, 36, 26))",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    width: "75%",
    minHeight: "80vh",
    backgroundColor: "rgb(10, 36, 26))",
    borderRadius: 16,
    boxShadow: "4px 2px 12px 8px var(--shadow)",
    display: "grid",
    gridTemplateColumns: "40% 60%",
    "@media screen and (max-width: 820px)": {
      gridTemplateColumns: "1fr",
      width: "inherit",
      margin: "0 20px",
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "70%",
    maxHeight: "100%",
  },
  leftSide: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#E7EAED",
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    "@media screen and (max-width: 820px)": {
      borderRadius: "16px",
    },
  },
  logo: {
    height: 115,
    "& > img": {
      height: 115,
    },
  },
  form: {
    margin: "32px 10px 0px 50px",
    width: "70%",
    "@media screen and (max-width: 820px)": {
      margin: "0",
      padding: "40px 20px",
      width: "inherit",
    },
  },
  formGroup: {
    width: "100%",
  },
  textfield: {
    marginBottom: 16,
    // width: '25ch',
  },
  btn: {
    borderRadius: "100px",
    marginTop: 25,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  rightSide: {
    backgroundImage:
      "linear-gradient(to right, rgba(70, 222, 164, 0.4), rgb(10, 36, 26))",
    borderTopRightRadius: 16,
    borderBottomRightRadius: 16,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "64px 148px",
    "@media screen and (max-width: 820px)": {
      display: "none",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: "100%",
  },
  err: {
    color: "red",
  },
}));

const Login = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  // const [pass, setPass] = useState("");
  // const [userDetails, setUserDetails] = useState();
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  const [values, setValues] = React.useState({
    error: false,
    errMsg: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      error: values.error === true ? !values.error : values.error,
    });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlechangeEmail = (e) => {
    setEmail(e.target.value);
  };
  // const handlechangePass = (e) => {
  //   setPass(e.target.value);
  // };

  const handleLogin = () => {
    console.log(email, values.password);

    axios
      .get(`${APIBASE_URL}/GreenUser?Email=${email}&pwd=${values.password}`)
      .then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          // setUserDetails(res.data);
          localStorage.setItem("MemberDetails", JSON.stringify(res.data));
          localStorage.setItem("uId", res.data[0]?.UniqueKey); //"HZ833681-DS967124";
          localStorage.setItem("MemberCode", res.data[0]?.MemberCode);
          localStorage.setItem("usr_role", res.data[0]?.UserRole);
          localStorage.setItem(
            "UserName",
            res.data[0]?.FName + " " + res.data[0]?.LName
          );
          localStorage.setItem("UserNameF", res.data[0]?.FName);
          localStorage.setItem("UserNameL", res.data[0]?.LName);

          localStorage.setItem("Cell", res.data[0]?.Cell);
          localStorage.setItem("Phone", res.data[0]?.Phone);
          localStorage.setItem("JobTitle", res.data[0]?.JobTitle);
          localStorage.setItem("LKey", res.data[0]?.LoginKey);
          localStorage.setItem("Name", res.data[0]?.Company);
          localStorage.setItem("PageHeader", "Company Details");
          dispatch(changeMembercode(res.data[0]?.MemberCode));
          dispatch(changeLoginKey(res.data[0]?.LoginKey));
          navigate("/MemberDetails");
        } else {
          setValues({
            ...values,
            error: !values.error,
            errMsg: "User id-Password Mismatch!",
          });

          console.log("user id-password mismatch!");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <div className={classes.leftSide}>
          <div className={classes.logo}>
            <img
              src={require("../Greenabl Logo_stacked_full color.png")}
              alt="logo"
            />
          </div>
          <div className={classes.form}>
            <FormControl
              variant="outlined"
              classes={{ root: classes.formGroup }}
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <OutlinedInput
                id="email"
                value={email}
                fullWidth
                onChange={handlechangeEmail}
                label="Email"
                className={classes.textfield}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              classes={{ root: classes.formGroup }}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                className={classes.textfield}
                fullWidth
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={100}
              />
              {values.error && (
                <FormHelperText
                  id="component-error-text"
                  className={classes.err}
                >
                  {values.errMsg}
                </FormHelperText>
              )}
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.btn}
              onClick={handleLogin}
            >
              Login
            </Button>
          </div>
          <div style={{ marginTop: 32 }}>
            Forgot password?{" "}
            <Link to={"/"} className={classes.link}>
              Click here
            </Link>
          </div>
        </div>
        <div className={classes.rightSide}>
          <Typography variant="h3">
            Welcome <br />
            to the <br /> Greenabl <br /> Platform!
          </Typography>
          <Typography style={{ marginTop: 32 }} variant="subtitle1">
            A digital platform combined with best-in-class research and
            consulting support to help shippers take a holistic approach to
            mitigating their carbon footprint
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Login;
