import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { red } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CancelOutlined } from "@material-ui/icons";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 645,
    backgroundImage:
      "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
  },
  rootA: {
    maxWidth: 645,
  },
  table: {
    minWidth: 1050,
    height: 700,
    overflowY: "auto",
  },
  textSecondary: {
    color: "#ffffff",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  media: {
    height: 200,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  smallWidth: {
    width: 400,
  },
  largeWidth: {
    width: 1100,
  },
  progressWidth: {
    width: 300,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  greenBG: {
    backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require("../../assets/img/topBack.jpg")})`,
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: 8,
  },
  shadow: {
    boxShadow: "3px 2px 14px 1px rgba(20,19,19,0.75)",
    // -webkit-box-shadow: 3px 2px 14px 1px rgba(20,19,19,0.75);
    // -moz-box-shadow: 3px 2px 14px 1px rgba(20,19,19,0.75);
  },
}));

const ProjectDetails = ({ pid, handleClose }) => {
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;

  const classes = useStyles();
  const [projectDetails, setprojectDetails] = useState([]);
  const [projectImageDetails, setprojectImageDetails] = useState([]);
  const [center, setcenter] = useState({ lat: 46.658773, lng: -98.339812 });
  // eslint-disable-next-line no-unused-vars
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAJv5cjWWDCJa_4Y39L9RYufUltheQgh8o",
  });
  // const viewProjectDetails = (pid) => {
  useEffect(() => {
    const ProjId = pid;
    axios
      .get(
        // `http://api.greenabl.us/api/GreenMemberProject?ProjectId=2&PrjectDetails=Y`
        `${APIBASE_URL}/GreenMemberProject?ProjectId=${ProjId}&PrjectDetails=Y`
      )
      .then((res) => {
        console.log(res.data);
        var latx = res.data[0].locX;
        var laty = res.data[0].locY;
        setcenter({ lat: latx, lng: laty });

        const comDetails = res.data;
        setprojectDetails(comDetails);

        axios
          .get(
            // `http://api.greenabl.us/api/GreenMemberProject?ProjectId=2&imgFlag=1`
            `${APIBASE_URL}/GreenMemberProject?ProjectId=${ProjId}&imgFlag=1`
          )
          .then((res) => {
            console.log(res.data);

            const comDetails = res.data;
            setprojectImageDetails(comDetails);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
        // setprojectDetails();
      });

    // handsleOpen();
    // };
  }, [APIBASE_URL, pid]);

  // const handleOpen = () => {
  //     setOpen(true);
  //     console.log(projectDetails);

  // };

  // const handleClose = () => {

  //     setOpen(false);
  // };

  return (
    <div
      className={`${classes.paper} ${classes.table} ${classes.greenBG} ${classes.largeWidth}`}
    >
      <Grid item sm={12} md={12} lg={12} style={{ margin: "auto" }}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={12}>
            <Card>
              <IconButton
                aria-label="close"
                color="primary"
                onClick={handleClose}
                style={{ float: "right" }}
              >
                <CancelOutlined />
              </IconButton>
              <CardContent>
                <Typography variant="h5" style={{ textAlign: "left" }}>
                  <strong>Project Name: </strong>
                  {projectDetails[0]?.PName}
                </Typography>
                <Typography variant="body1" style={{ textAlign: "left" }}>
                  <strong>Project Location: </strong>
                  {projectDetails[0]?.ProjectLocation}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={12} md={8}>
            <Card>
              <CardContent>
                <img
                  alt="Main project"
                  src={projectDetails[0]?.imgUrl}
                  className={`${classes.image} ${classes.shadow}`}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12} md={4}>
            <Card style={{ height: 405 }}>
              <CardContent>
                <Grid item sm={12}>
                  <Typography variant="h6" style={{ height: 50 }}>
                    Project Details
                  </Typography>
                  <Typography variant="body2" style={{ height: 40 }}>
                    <strong>Methodology Type:</strong>{" "}
                    {projectDetails[0]?.MethodType}
                  </Typography>
                  <Typography variant="body2" style={{ height: 35 }}>
                    <strong>Registry:</strong> {projectDetails[0]?.Registry}
                  </Typography>
                  <Typography
                    variant="body2"
                    margin="35"
                    style={{ height: 50 }}
                  >
                    <strong>Project ID:</strong>{" "}
                    {projectDetails[0]?.registry_project_id}
                  </Typography>
                  <Typography
                    variant="body2"
                    marginTop="35"
                    style={{ height: 50 }}
                  >
                    <strong>Offset Classification:</strong>{" "}
                    {projectDetails[0]?.offset_classification}
                  </Typography>
                  {/*  <Typography variant="body2" style={{ height:30 }}>
                                           <strong>Registry Listing Ratings:</strong>{" "}
                                             {projectDetails[0]?.registry_project_id}
                                         </Typography> */}
                  <Typography
                    variant="body2"
                    marginTop="50"
                    style={{ height: 50 }}
                  >
                    <strong>
                      Available CO<sub>2</sub>e:
                    </strong>{" "}
                    {projectDetails[0]?.PAvailable_carbon_in_kg / 1000} MTon
                  </Typography>
                  <Typography variant="body2" marginTop="50">
                    <strong>Offset Cost per MTon:</strong> $
                    {projectDetails[0]?.Pcost_per_kg_carbon_in_usd_cents * 10}
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={12} md={12}>
            <Card style={{ marginTop: 15 }}>
              <CardContent>
                <Typography variant="h6">Location on Map</Typography>
                <div style={{ height: 450, width: "100%" }}>
                  {isLoaded && (
                    <GoogleMap
                      center={center}
                      zoom={7}
                      mapContainerStyle={{ width: "100%", height: "100%" }}
                      options={{
                        //zoomControl:false,
                        streetViewControl: false,
                        fullscreenControl: false,
                        mapTypeControl: false,
                      }}
                    >
                      <MarkerF
                        position={{
                          lat: projectDetails[0]?.locX,
                          lng: projectDetails[0]?.locY,
                        }}
                        icon={{
                          url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                        }}
                      />
                    </GoogleMap>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={12} md={12}>
            <Card>
              <CardContent>
                <Typography variant="h5">Project Description:</Typography>
                <Typography variant="body1" style={{ textAlign: "left" }}>
                  {projectDetails[0]?.short_description}
                </Typography>
                <Grid container spacing={3} style={{ padding: 15 }}>
                  {projectImageDetails[0] && (
                    <Grid item sm={4}>
                      <img
                        alt=""
                        src={projectImageDetails[0]?.imgUrl}
                        className={`${classes.image} ${classes.shadow}`}
                      />
                    </Grid>
                  )}
                  {projectImageDetails[1] && (
                    <Grid item sm={4}>
                      <img
                        alt=""
                        src={projectImageDetails[1]?.imgUrl}
                        className={`${classes.image} ${classes.shadow}`}
                      />
                    </Grid>
                  )}
                  {projectImageDetails[2] && (
                    <Grid item sm={4}>
                      <img
                        alt=""
                        src={projectImageDetails[2]?.imgUrl}
                        className={`${classes.image} ${classes.shadow}`}
                      />
                    </Grid>
                  )}
                </Grid>

                <Typography variant="body1" style={{ textAlign: "left" }}>
                  {projectDetails[0]?.long_description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectDetails;
