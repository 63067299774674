import { CardActionArea, Grid, Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Edit, VpnKey } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import ProfileImage from "../../assets/img/Robot Avatars_4.png";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 645,
    backgroundImage:
      "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
  },
  table: {
    minWidth: 500,
    height: 300,
    overflowY: "auto",
  },
  paperContainer1: {
    backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require("../../assets/img/topBack.jpg")})`,
    backgroundPosition: "0,0",
    backgroundColor: "#174C38",
    padding: "35px",
    marginBottom: 20,
    "@media screen and (max-width: 576px)": {
      padding: 10,
    },
  },
  GridContainer: {
    minHeight: 356,
    minWidth: 900,
    backgroundPosition: "0,0",
    marginBottom: 20,
  },
  textSecondary: {
    color: "#ffffff",
  },
  //   smallWidth: {
  //     width: 400,
  //   },
  //   largeWidth: {
  //     width: 600,
  //   },
  //   progressWidth: {
  //     width: 300,
  //   },
  //   modal: {
  //     display: "flex",
  //     alignItems: "center",
  //     justifyContent: "center",
  //   },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  memeberGrid: {
    display: "grid",
    gap: 10,
    gridTemplateColumns: "1fr",
    "@media screen and (max-width: 576px)": {
      gridTemplateColumns: "1fr",
    },
  },
  large: {
    width: theme.spacing(22),
    height: theme.spacing(22),
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UserprofileContent = () => {
  const classes = useStyles();
  // const APIBASE_URL = useMemo(() => process.env.REACT_APP_APIBASE_URL, []);
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);
  // const [selectedImage, setSelectedImage] = useState(null);
  const [companyDetails, setCompanyDetails] = useState({});
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  // const navigate = useNavigate();
  const [state, setState] = useState({
    FName: "",
    MName: "",
    LName: "",
    JobTitle: "",
    Cell: "",
    Phone: "",
  });

  const [openChangePassDialog, setOpenChangePassDialog] = useState(false);
  const [openEditprofDialog, setOpenEditprofDialog] = useState(false);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");

  const [openSnkbar, setOpenSnkbar] = useState(false);
  const [succMsg, setSuccMsg] = useState("");
  const [severity, setSeverity] = useState("success");

  useEffect(() => {
    setCompanyDetails(JSON.parse(localStorage.getItem("MemberDetails")));
  }, [MemberCode, openSnkbar]);

  // console.log(localStorage.getItem("MemberDetails"));
  // console.log(companyDetails);
  // console.log(companyDetails[0]?.Email);

  const changePassword = () => {
    const LoginKey = companyDetails[0]?.LoginKey;
    // alert("change pass");
    // alert(oldPass);
    // alert(newPass);
    axios
      .get(
        `${APIBASE_URL}/GreenUser?LoginKey=${LoginKey}&NewPwd=${newPass}&OldPwd=${oldPass}`
      )
      .then((res) => {
        // console.log(res);
        if (res.data === "success") {
          setSuccMsg("password changed Successfuly");
          setOpenSnkbar(true);
          handleClose();
          localStorage.clear();
          // navigate("/Login");
        } else {
          setSeverity("warning");
          setSuccMsg(res.data);
          setOpenSnkbar(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleClickOpenProf = () => {
    setState({
      FName: localStorage.getItem("UserNameF"),
      MName: localStorage.getItem("UserNameM"),
      LName: localStorage.getItem("UserNameL"),
      JobTitle: localStorage.getItem("JobTitle"),
      Cell: localStorage.getItem("Cell"),
      Phone: localStorage.getItem("Phone"),
    });
    setOpenEditprofDialog(true);
  };
  const handleClickOpenPass = () => {
    setOpenChangePassDialog(true);
  };

  const handleClose = () => {
    setOpenChangePassDialog(false);
  };
  const handleCloseProf = () => {
    setState({
      FName: "",
      MName: "",
      LName: "",
      JobTitle: "",
      Cell: "",
      Phone: "",
    });
    setOpenEditprofDialog(false);
  };

  const handleOldPass = (e) => {
    setOldPass(e.target.value);
  };

  const handleNewPass = (e) => {
    setNewPass(e.target.value);
  };

  const handleChangeProfile = (e) => {
    console.log(e.target.name);
    console.log(e.target.value);

    setState((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleEditProfile = () => {
    console.log();
    console.log(state);
    const user = {
      FName: state.FName,
      LName: state.LName,
      LoginKey: companyDetails[0]?.LoginKey,
      JobTitle: state.JobTitle,
      Phone: state.Phone,
      Cell: state.Cell,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    axios
      .post(`${APIBASE_URL}/GreenUser`, user, requestOptions)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 200) {
          // alert("edit profile success");
          localStorage.setItem("UserName", state.FName + " " + state.LName);
          localStorage.setItem("UserNameF", state.FName);
          localStorage.setItem("UserNameM", "");
          localStorage.setItem("UserNameL", state.LName);
          localStorage.setItem("Cell", state.Cell);
          localStorage.setItem("Phone", state.Phone);
          localStorage.setItem("JobTitle", state.JobTitle);
          setOpenEditprofDialog(false);
          setSuccMsg("Profile changed Successfuly");
          setOpenSnkbar(true);
        } else {
          setSeverity("warning");
          setOpenEditprofDialog(false);
          setSuccMsg("Profile change was not Successful! Please try again");
          setOpenSnkbar(true);
          const failedElement = {
            MemberCode: MemberCode,
            ContainerNo: "",
            SH_ID: "",
            ErrorNo: resp.status,
            ErrorDesc: "Profile change was not Successful",
            ModuleName: "UserorifileContent.js: Edit user profile",
          };
          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
          };

          axios
            .post(`${APIBASE_URL}/GreenErrorLog`, failedElement, requestOptions)
            .then((res) => {
              console.log(res);
            });
        }
      });
  };

  return (
    <>
      <Grid container spacing={2} className={classes.paperContainer1}>
        <Grid item sx={12} sm={3} md={3} lg={3}>
          <Card style={{ borderRadius: 15, paddingBottom: 20 }}>
            <div
              style={{
                padding: 15,
                backgroundColor: "#fff",
                borderRadius: 4,
                display: "flex",
                flexDirection: "column",
                alignContent: "space-around",
                alignItems: "center",
              }}
            >
              <Avatar
                alt={localStorage.getItem("UserName")}
                src={ProfileImage}
                className={classes.large}
              />
              <Typography variant="h5">
                {localStorage.getItem("UserName")}
              </Typography>
              <Typography variant="subtitle1">
                {companyDetails[0]?.JobTitle}
              </Typography>
              <Typography variant="subtitle1">
                {companyDetails[0]?.Email}
              </Typography>
              {/* <Typography variant="h6" component="body2">
                <Button >Change Password</Button>
              </Typography> */}
            </div>
          </Card>
        </Grid>
        <Grid item sx={12} sm={6} md={6} lg={6}>
          <Card style={{ borderRadius: 15 }}>
            <div className={classes.memeberGrid}>
              <div
                style={{
                  padding: "20px 35px 20px 55px",
                  backgroundColor: "#fff",
                  borderRadius: 14,
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    border: "1px solid red",
                  }}
                >
                 
                </div>  */}
                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    Company
                  </Grid>
                  <Grid item sm={9}>
                    {companyDetails[0]?.Company}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    Full Name
                  </Grid>
                  <Grid item sm={9}>
                    {localStorage.getItem("UserName")}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    Job Title
                  </Grid>
                  <Grid item sm={9}>
                    {localStorage.getItem("JobTitle")}
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    Cell
                  </Grid>
                  <Grid item sm={9}>
                    {localStorage.getItem("Cell")}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    Phone
                  </Grid>
                  <Grid item sm={9}>
                    {localStorage.getItem("Phone")}
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    Primary Contact
                  </Grid>
                  <Grid item sm={9}>
                    {companyDetails[0]?.PrimaryContact === "Y" ? "Yes" : "No"}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item sm={3}>
                    User Role
                  </Grid>
                  <Grid item sm={9}>
                    {companyDetails[0]?.UserRole > 49
                      ? "Admin"
                      : "Not an Admin"}
                  </Grid>
                </Grid>
              </div>
            </div>
            <CardActionArea
              style={{
                display: "flex",
                flexDirection: "row",
                borderTop: "2px solid #005c49",
                backgroundColor: "#95f2df",
              }}
            >
              <Tooltip title="Change Password">
                <IconButton
                  aria-label="change password"
                  onClick={handleClickOpenPass}
                  color="primary"
                >
                  <VpnKey color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit profile">
                <IconButton
                  aria-label="Edit profile"
                  color="primary"
                  onClick={handleClickOpenProf}
                >
                  <Edit color="primary" />
                </IconButton>
              </Tooltip>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={openEditprofDialog}
        onClose={handleCloseProf}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit Your Profile</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please change the fields whichever required.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="FName"
            name="FName"
            value={state.FName}
            label="First Name"
            type="text"
            fullWidth
            onChange={(e) => {
              handleChangeProfile(e);
            }}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="MName"
            name="MName"
            value={state.MName}
            label="Middle Name"
            type="text"
            fullWidth
            onChange={(e) => {
              handleChangeProfile(e);
            }}
          /> */}
          <TextField
            autoFocus
            margin="dense"
            id="Lname"
            name="LName"
            value={state.LName}
            label="Last Name"
            type="text"
            fullWidth
            onChange={(e) => {
              handleChangeProfile(e);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="JobTitle"
            name="JobTitle"
            value={state.JobTitle}
            label="Job Title"
            type="text"
            fullWidth
            onChange={(e) => {
              handleChangeProfile(e);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="Cell"
            name="Cell"
            value={state.Cell}
            label="Cell Number"
            type="text"
            fullWidth
            onChange={(e) => {
              handleChangeProfile(e);
            }}
          />
          <TextField
            margin="dense"
            id="Phone"
            name="Phone"
            value={state.Phone}
            label="Phone Number"
            type="text"
            fullWidth
            onChange={(e) => {
              handleChangeProfile(e);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProf} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditProfile} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openChangePassDialog}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Change Your Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please give your Old password and put new password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="oldPass"
            label="Old Password"
            type="password"
            fullWidth
            onChange={(e) => {
              handleOldPass(e);
            }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="newPass"
            label="New Password"
            type="password"
            fullWidth
            onChange={(e) => {
              handleNewPass(e);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={changePassword} color="primary">
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
      {/* <div>
        <h1>Upload and Display Image usign React Hook's</h1>

        {selectedImage && (
          <div>
            <img
              alt="not found"
              width={"250px"}
              src={URL.createObjectURL(selectedImage)}
            />
            <br />
            <button onClick={() => setSelectedImage(null)}>Remove</button>
          </div>
        )}

        <br />
        <br />

        <input
          type="file"
          name="myImage"
          onChange={(event) => {
            console.log(event.target.files[0]);
            setSelectedImage(event.target.files[0]);
          }}
        />
      </div> */}
      <Snackbar open={openSnkbar} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {succMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserprofileContent;
