import { FormControl, Grid, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Link from "@material-ui/core/Link";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@material-ui/data-grid";
import {
  AssignmentTurnedIn,
  CancelOutlined,
  CloudDownload,
  Delete,
  Edit,
  Input,
  PostAdd,
  Visibility,
} from "@material-ui/icons";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import Moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
//import { read, utils } from "xlsx";
import DateFnsUtils from "@date-io/date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import FileSaver from "file-saver";
import { useLocation } from "react-router-dom";
import { LCL_EXCEL_FILE_BASE64 } from "./LCLexcelTemplateConstant";
import DeleteCellRenderer from "./deleteCellRenderer";
import moment from "moment/moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";


function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 645,
    backgroundImage:
      "linear-gradient(to right, rgba(00, 92, 73,1), rgb(10, 36, 26))",
  },

  paperContainer1: {
    height: 356,
    minWidth: 900,
    backgroundImage: `linear-gradient(to right bottom, rgba(255,255,255,.1), rgba(255,255,255,.1)), url(${require("../../assets/img/Back_Projects_part1.png")})`,
    backgroundPosition: "0,0",
    backgroundColor: "#174C38",
    marginBottom: 20,
  },

  // GridContainer: {
  //   minHeight: 356,
  //   minWidth: 900,
  //   width: "95%",
  //   backgroundPosition: "0,0",
  //   marginBottom: 20,
  // },
  GridContainer: {
    minHeight: 356,
    margin: "0 20px",
    "@media screen and (max-width:576px)": {
      margin: "0",
    },
  },
  textSecondary: {
    color: "#ffffff",
  },
  paper: {
    position: "absolute",

    backgroundColor: "var(--neutralOffWhite)",
    border: "2px solid #000",
    boxShadow: "var(--neutralGray)",
    padding: "0px 25px 15px 30px",
  },
  smallWidth: {
    width: 400,
  },
  mediumWidth: {
    width: 900,
  },
  largeWidth: {
    width: "90%",
    maxWidth: 1200,
  },
  textdata: {
    color: "#1164B4",
    fontSize: "14px",
    fontWeight: "bold",
  },
  height: {
    height: 600,
    overflow: "auto",
  },
  progressWidth: {
    width: 300,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper1: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[9],
    padding: theme.spacing(2, 4, 3),
  },
  btn: {
    border: "1px solid #666",
    borderRadius: 0,
    padding: "15px 10px",
    marginBottom: "65px",
    textAlign: "center",
    cursor: "pointer",
  },
  media: {
    height: 50,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
  correctRow: {
    background: "white",
  },
  incorrectRow: {
    background: red[100],
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const editButton = (props) => {
  const handleEditClick = () => {
    const dataToEdit = props.noncarbonNutralisedShipemnts.find(
      (el) => el.GR_SH_ID === props.value
    );
    console.log(dataToEdit);
    const selectedCarrier = props.availableCarriers.find(
      (el) => el.Carrier === dataToEdit.Carrier
    );
    const selectedOrigin = props.availableAllOriginDestinations.find(
      (el) => el.UNLoCode === dataToEdit.OriginLocCode
    );
    const selectedDestination = props.availableAllOriginDestinations.find(
      (el) => el.UNLoCode === dataToEdit.DestLocCode
    );
    const selectedLoadPort = props.availableAllOriginDestinations.find(
      (el) => el.UNLoCode === dataToEdit.LoadPortLocCode
    );
    const selectedDiscPort = props.availableAllOriginDestinations.find(
      (el) => el.UNLoCode === dataToEdit.DischargePortLocCode
    );
    const selectedTransShpPort = props.availableAllOriginDestinations.find(
      (el) => el.UNLoCode === dataToEdit.TransPortLocCode
    );
    const selectedCyRamp = props.availableAllOriginDestinations.find(
      (el) => el.UNLoCode === dataToEdit.InlandCYRampCode
    );
    const originValue = dataToEdit.Origin;
    const loadPortValue = dataToEdit.LoadPort;
    const discPortValue = dataToEdit.DischargePort;
    const transShpPortValue = dataToEdit.TransPort;
    const cyRampValue = dataToEdit.InlandCYRamp;
    const destinationValue = dataToEdit.Destination;
    const carrierValue = dataToEdit.Carrier;
    const dischargePlaceValue = dataToEdit.DischargePort;
    const selectedTrnsModeORGN = props.availableTRansMode.find(
      (el) => el.mode === dataToEdit?.OriginToLoadPortTranMode
    );
    const selectedTrnsModeLOAD = props.availableTRansMode.find(
      (el) => el.mode === dataToEdit?.LoadPortToTransPortTranMode
    );
    const selectedTrnsModeDISC = props.availableTRansMode.find(
      (el) => el.mode === dataToEdit?.DischargePortToInlandRampTranMode
    );
    // const selectedTrnsModeCYRAMP = props.availableTRansMode.find(
    //   (el) => el.mode === dataToEdit?.InlandRampToDestTranMode
    // );
    const slectedBLType = props.availableBlType.find(
      (el) => el.type === dataToEdit?.BLType
    );
    const containerNo = dataToEdit.ContainerNo;
    let containerSize = null;
    if (dataToEdit.ContainerSize === "40NOR") {
      containerSize = { Size: "40GP" };
      props.setNor40(true);
    } else {
      containerSize = props.availableContSize.find(
        (el) => el.Size === dataToEdit.ContainerSize
      );
      props.setNor40(false);
    }

    const vessel = dataToEdit.Vessel;
    const voyage = dataToEdit.Voyage;
    const comment1 = dataToEdit.Comment1;
    const comment2 = dataToEdit.Comment2;
    const transShipvessel = dataToEdit.transShipvessel;
    const selectedPOLDate = dataToEdit.selectedPOLDate;
    const transMode = dataToEdit.TransPortLocCode ? true : false;
    const piece = dataToEdit.NoOfCartons;
    const weight = dataToEdit.TotalWeight;
    const volume = dataToEdit.TotalVolume;
    const blnumber = dataToEdit.BLNo;

    props.setState((prev) => ({
      ...prev,
      selectedCarrier,
      selectedOrigin,
      selectedDestination,
      selectedLoadPort,
      selectedDiscPort,
      selectedTransShpPort,
      selectedCyRamp,
      // selectedPlaceofOrigin: undefined,
      originValue,
      loadPortValue,
      discPortValue,
      transShpPortValue,
      cyRampValue,
      destinationValue,
      // originPlaceValue: "",
      carrierValue,
      dischargePlaceValue,
      selectedTrnsModeORGN,
      selectedTrnsModeLOAD,
      selectedTrnsModeDISC,
      selectedTrnsModeTRANS: { mode: "Sea" },
      slectedBLType,
      containerNo,
      containerSize,
      vessel,
      voyage,
      piece,
      weight,
      volume,
      blnumber,
      comment1,
      comment2,
      transShipvessel,
      selectedPOLDate,
      addShipmentAction: "Edit",
      shipmentIdToEdit: props.value,
    }));

    props.setTransShipments(transMode);

    props.setExportModalOpen(true);
    // alert(props.value);
  };
  return (
    <Button onClick={handleEditClick}>
      <Edit></Edit>
    </Button>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const LCLShipments = () => {
  const classes = useStyles();
  const mounted = useRef(true);

  const gridRef = useRef();
  let gridApi = React.useRef(null);
  const APIBASE_URL = process.env.REACT_APP_APIBASE_URL;
  const location = useLocation();
  // const uId = "HZ833681-DS967124";
  // const MemberCode = localStorage.getItem('MemberCode');
  const [isLoadingCircle, setIsLoadingCircle] = useState(false);
  const [isLoadingBar, setIsLoadingBar] = useState(false);
  const MemberCode = useSelector((state) => state.memberreducer.MemberCode);
  const [dialogViewShipmentOpen, setDialogViewShipmentOpen] = useState(false);
  const [projectListDetails, setProjectListDetails] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [nor40, setNor40] = useState(false);
  const [transshipments, setTransShipments] = useState(false);
  const navigate = useNavigate();
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [noncarbonNutralisedShipemnts, setNonCarbonNutralisedShipemnts] =
    useState([]);
  const [selectedIds, setSelectedIDs] = useState([]);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [emitionOffsetModalOpen, setEmitionOffsetModalOpen] = useState(false);
  const [OffsetSuccessModalOpen, setOffsetSuccessModalOpen] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);
  const [addShipmentSuccess, setAddShipmentSuccess] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [availableAllOriginDestinations, setAvailableAllOriginDestinations] =
    useState([{}]);
  const [availableCarriers, setavailableCarriers] = useState([{}]);
  const [projChekbox, setProjChekbox] = useState([]);
  const [availableContSize] = useState([
    { Size: "20GP" },
    { Size: "40GP" },
    { Size: "40HC" },
    { Size: "45G1" },
    { Size: "22R1" },
    { Size: "40REHC" },
    { Size: "53FT" },
  ]);
  const [availableBlType] = useState([
    { type: "Door-CY" },
    { type: "Door-Door" },
    { type: "CY-CY" },
    { type: "CY-Door" },
    { type: "CY-CY/Ramp" },
    { type: "Door-CY/Ramp" },
  ]);
  const [availableTRansMode] = useState([
    { mode: "road", view: "Truck" },
    { mode: "rail", view: "Rail" },
    { mode: "inland-water", view: "Barge" },
    { mode: "sea", view: "Sea" },
    { mode: "air", view: "Air" }, // Air
  ]);

  // const [certificateIdforView, setCertificateIdforView] = useState();
  const CloseButton = ({ onClose }) => {
    return (
      <IconButton
        aria-label="close"
        color="primary"
        onClick={onClose}
        style={{ float: "right" }}
      >
        <CancelOutlined />
      </IconButton>
    );
  };
  const [state, setState] = useState({
    selectedCarrier: undefined,
    selectedOrigin: undefined,
    selectedDestination: undefined,
    selectedLoadPort: undefined,
    selectedDiscPort: undefined,
    selectedTransShpPort: undefined,
    selectedCyRamp: undefined,
    selectedPlaceofOrigin: undefined,
    originValue: "",
    loadPortValue: "",
    discPortValue: "",
    transShpPortValue: "",
    cyRampValue: "",
    destinationValue: "",
    originPlaceValue: "",
    carrierValue: "",
    dischargePlaceValue: "",
    fileName: "Choose Excel File to Import Shipments",
    selectedTrnsModeORGN: availableTRansMode[3],
    selectedTrnsModeLOAD: availableTRansMode[3],
    selectedTrnsModeDISC: availableTRansMode[1],
    selectedTrnsModeCYRAMP: availableTRansMode[0],
    selectedTrnsModeTRANS: "Sea",
    availableDestinations: [{}],
    availablePortofDischarge: [{}],
    availableCarriers: [{}],
    slectedBLType: availableBlType[2],
    containerNo: "",
    piece: 0,
    weight: 0,
    volume: 0,
    blnumber: "",
    containerSize: availableContSize[2],
    vessel: "",
    voyage: "",
    transMode: "",
    transShipvessel: "",
    selectedPOLDate: moment().format("L"),
    totalCO2Emision: 0,
    isChecked: false,
    addShipmentAction: "ADD",
    shipmentIdToEdit: "",
    OriginAddress: "",
    OriginZIPCode: "",
    DestinationAddress: "",
    DestinationZIPCode: "",
    comment1: "",
    comment2: "",
  });
  // const [transpType, setTranspType] = useState([
  //   {
  //     FromLocCode: "",
  //     ToLocCode: "",
  //     TransportType: "",
  //   },
  // ]);
  // const [containerSize, setContainerSize] = useState(20);
  const [selectedRows, setSelectedRows] = useState([]);
  const [errCR, setErrCR] = useState(false);
  const [errCntnr, setErrCntnr] = useState(false);
  // const [errBLno, setErrBLno] = useState(false);
  const [errPiece, setErrPiece] = useState(false);
  const [errWght, setErrWght] = useState(false);
  const [errVolm, setErrVolm] = useState(false);
  const [errBlNo, setErrBlNo] = useState(false);
  const [errCntSz, setErrCntSz] = useState(false);
  const [errBL, setErrBL] = useState(false);
  const [errOrgn, setErrOrgn] = useState(false);
  const [errDest, setErrDest] = useState(false);
  const [errDiscPrt, setErrDiscPrt] = useState(false);
  const [errLoadPrt, setErrLoadPrt] = useState(false);
  const handleViweShipmentDialogClose = () => {
    setDialogViewShipmentOpen(false);
  };
  // const Filter = "Y";
  const handleOriginValueChange = (_, v) => {
    let text = v;
    if (typeof v === "object") {
      text = `${
        v.selectedOrigin.City +
        ", " +
        v.selectedOrigin.StateProvince +
        ", " +
        v.selectedOrigin.Country +
        "- [" +
        v.selectedOrigin.UNLocode +
        "]"
      }`;
    }
    setState((prev) => ({ ...prev, originValue: text }));
  };
  const handleLoadPortValueChange = (_, v) => {
    let text = v;
    if (typeof v === "object") {
      text = `${
        v.selectedLoadPort.City +
        ", " +
        v.selectedLoadPort.StateProvince +
        ", " +
        v.selectedLoadPort.Country +
        "- [" +
        v.selectedLoadPort.UNLocode +
        "]"
      }`;
    }
    setState((prev) => ({ ...prev, loadPortValue: text }));
  };
  const handleDiscPortValueChange = (_, v) => {
    let text = v;
    if (typeof v === "object") {
      text = `${
        v.selectedDiscPort.City +
        ", " +
        v.selectedDiscPort.StateProvince +
        ", " +
        v.selectedDiscPort.Country +
        "- [" +
        v.selectedDiscPort.UNLocode +
        "]"
      }`;
    }
    setState((prev) => ({ ...prev, discPortValue: text }));
  };
  const handleTransShpPortValueChange = (_, v) => {
    let text = v;
    if (typeof v === "object") {
      text = `${
        v.selectedTransShpPort.City +
        ", " +
        v.selectedTransShpPort.StateProvince +
        ", " +
        v.selectedTransShpPort.Country +
        "- [" +
        v.selectedTransShpPort.UNLocode +
        "]"
      }`;
    }
    setState((prev) => ({ ...prev, transShpPortValue: text }));
    if (state.slectedBLType.type === "CY-CY") {
      if (state.selectedTransShpPort?.UNLoCode === undefined) {
        setState((prev) => ({
          ...prev,
          selectedTrnsModeORGN: availableTRansMode[3],
        }));
      } else {
        // OriginToLoadPortTranMode = "Barge";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeORGN: availableTRansMode[2],
        }));

        // TransPortToDischargePortTranMode = "Sea";
        /* setState((prev) => ({
            ...prev,
            selectedTrnsModeTRANS: availableTRansMode[3],
          })); */
      }
    } else if (
      state.slectedBLType.type === "CY-Door" ||
      state.slectedBLType.type === "CY-CY/Ramp"
    ) {
      if (state.selectedTransShpPort?.UNLoCode === undefined) {
        setState((prev) => ({
          ...prev,
          selectedTrnsModeORGN: availableTRansMode[3],
        }));
      } else {
        // OriginToLoadPortTranMode = "Barge";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeORGN: availableTRansMode[2],
        }));

        // TransPortToDischargePortTranMode = "Sea";
        setState((prev) => ({ ...prev, selectedTrnsModeTRANS: "Sea" }));
      }
    } else if (state.slectedBLType.type === "Door-CY") {
      // OriginToLoadPortTranMode = "Barge";
      setState((prev) => ({
        ...prev,
        selectedTrnsModeORGN: availableTRansMode[2],
      }));
      if (state.selectedTransShpPort?.UNLoCode === undefined) {
        // LoadPortToTranPortMode = "Sea";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeLOAD: availableTRansMode[3],
        }));
      } else {
        // LoadPortToTranPortMode = "Sea";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeLOAD: availableTRansMode[3],
        }));

        // TransPortToDischargePortTranMode = "Sea";
        setState((prev) => ({ ...prev, selectedTrnsModeTRANS: "Sea" }));
      }
    } else if (
      state.slectedBLType.type === "Door-Door" ||
      state.slectedBLType.type === "Door-CY/Ramp"
    ) {
      // OriginToLoadPortTranMode = "Barge";
      setState((prev) => ({
        ...prev,
        selectedTrnsModeORGN: availableTRansMode[2],
      }));
      if (state.selectedTransShpPort?.UNLoCode === undefined) {
        // LoadPortToTranPortMode = "Sea";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeLOAD: availableTRansMode[3],
        }));
      } else {
        // LoadPortToTranPortMode = "Sea";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeLOAD: availableTRansMode[3],
        }));

        // TransPortToDischargePortTranMode = "Sea";
        setState((prev) => ({ ...prev, selectedTrnsModeTRANS: "Sea" }));
      }
    }
  };
  const handleCyRampValueChange = (_, v) => {
    let text = v;
    if (typeof v === "object") {
      text = `${
        v.selectedCyRamp.City +
        ", " +
        v.selectedCyRamp.StateProvince +
        ", " +
        v.selectedCyRamp.Country +
        "- [" +
        v.selectedCyRamp.UNLocode +
        "]"
      }`;
    }
    setState((prev) => ({ ...prev, cyRampValue: text }));
  };

  const handleDestinationValueChange = (_, v) => {
    let text = v;
    if (typeof v === "object") {
      text = `${
        v.selectedOrigin.City +
        ", " +
        v.selectedOrigin.StateProvince +
        ", " +
        v.selectedOrigin.Country +
        "- [" +
        v.selectedOrigin.UNLocode +
        "]"
      }`;
    }
    setState((prev) => ({ ...prev, destinationValue: text }));
  };
  const handlecomment = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const [progress, setProgress] = React.useState(0);

  const viewShipemntDetails = () => {
    console.log(selectedRows);
    setDialogViewShipmentOpen(true);
  };

  const onSelectOrigine = (e, value) => {
    setState({ ...state, selectedOrigin: value });
    setErrOrgn(false);
    // alert(value.UNLocode);
    console.log(value);
  };
  const onSelectLoadPort = (e, value) => {
    setState({ ...state, selectedLoadPort: value });
    setErrLoadPrt(false);
    // alert(value.UNLocode);
    console.log(value);
  };
  const onSelectDiscPort = (e, value) => {
    setState({ ...state, selectedDiscPort: value });
    setErrDiscPrt(false);
    // alert(value.UNLocode);
    console.log(value);
  };
  const onSelectTransShpPort = (e, value) => {
    setState({ ...state, selectedTransShpPort: value });
    // alert(value.UNLocode);
    console.log(value);
  };
  const onSelectCYRamp = (e, value) => {
    setState({ ...state, selectedCyRamp: value });
    // alert(value.UNLocode);
    console.log(value);
  };
  const onSelectDestination = (e, value) => {
    setState({ ...state, selectedDestination: value });
    setErrDest(false);
    // alert(value.UNLocode);
    // console.log(GemCarrName);
  };
  const getShipmentList = () => {
    axios
      .get(
        // `http://api.greenabl.us/api/GreenShipments?MemberCode=1005&ShipmentType=N`
        `${APIBASE_URL}/GreenShipments?MemberCode=${MemberCode}&ShipmentType=N&LoadType=LCL`
      )
      .then((res) => {
        console.log(res.data);
        const carriers = res.data;
        const nwCrr = carriers.map((el) => {
          return {
            ...el,
            id: el.GR_SH_ID,
          };
        });
        setNonCarbonNutralisedShipemnts(nwCrr);
        console.log(nwCrr);
      })
      .catch(() => {
        setNonCarbonNutralisedShipemnts([]);
      });
  };

  const handleDownloadCNNS = () => {
     // Function to rename columns
     const renameColumns = (data) => {
      return data.map((item) => {
        //console.log(item);
        let newItem = {};
        for (let key in item) {
          if (key === "CO2Emission_in_grams") {
            newItem["CO2Emission_in_Mton"] = item[key];
          }else{
            newItem[key] = item[key];
          }
        }
        return newItem;
      });
    };
    const newCNNDATA = renameColumns(noncarbonNutralisedShipemnts);
    console.log(newCNNDATA);
    
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(newCNNDATA);

    XLSX.utils.book_append_sheet(wb, ws, "Carbon non-nuetralized Shipment");
    XLSX.writeFile(wb, "Carbon-non-nuetralized-ShipmentDetails.xlsx");
  };

  const handleDownloadTemplate = () => {
    console.log("DownloadTemplate");
    let sliceSize = 1024;
    let byteCharacters = atob(LCL_EXCEL_FILE_BASE64);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    FileSaver.saveAs(
      new Blob(byteArrays, { type: "application/vnd.ms-excel" }),
      "LCLDataImport-Template.xlsx"
    );
  };

  useEffect(() => {
    axios
      .get(
        // `http://api.greenabl.us/api/GreenMemberProject?MemberCode=1005`
        `${APIBASE_URL}/GreenMemberProject?MemberCode=${MemberCode}`
      )
      .then((res) => {
        console.log(res.data);
        if (mounted.current) {
          if (projectListDetails.length === 0) {
            const projDetails = res.data;
            setProjectListDetails(projDetails);
            var defaultValues = [];
            var idArray = [];
            setSelectedIDs([]);

            res.data.forEach((el) => {
              setSelectedIDs((prev) => [...prev, el.PId]);
              idArray.push(el.PId);
              defaultValues.push("true");
            });
            if (selectedIds.length === 0) {
              setSelectedIDs(idArray);
            }

            console.log(defaultValues);
            console.log(idArray);
            setProjChekbox(defaultValues);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APIBASE_URL, MemberCode, selectedIds.length]);

  useEffect(() => {
    getShipmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MemberCode, addShipmentSuccess]);

  useEffect(() => {
    mounted.current = true;

    axios
      .get(
        // `http://api.greenabl.us/api/GreenCarrier?MemberCode=1005`
        `${APIBASE_URL}/GreenCarrier?MemberCode=${MemberCode}`
      )
      .then((res) => {
        console.log(res.data);
        if (mounted.current) {
          const carriers = res.data;
          setavailableCarriers(carriers);
          //console.log(carriers);
        }
      })
      .catch(() => {
        setavailableCarriers([]);
      });

    return () => {
      mounted.current = false;
    };
  }, [APIBASE_URL, MemberCode]);

  useEffect(() => {
    mounted.current = true;
    axios
      .get(`${APIBASE_URL}/GreenLocations?LocType=All`)
      .then((res) => {
        console.log(res.data);
        if (mounted.current) {
          const DestiPorts = res.data;
          setAvailableAllOriginDestinations(DestiPorts);
          // console.log(MemberContacts);
        }
      })
      .catch(() => {
        setAvailableAllOriginDestinations([]);
      });
    return () => {
      mounted.current = false;
    };
  }, [APIBASE_URL]);

  useEffect(() => {
    if (state.addShipmentAction === "Edit") {
      return;
    }
    if (state.slectedBLType.type === "CY-Door") {
      setState((prev) => ({
        ...prev,
        selectedTrnsModeORGN: availableTRansMode[3],
      }));
      setState((prev) => ({ ...prev, selectedTrnsModeTRANS: "Sea" }));

      if (state.selectedCyRamp?.UNLoCode !== "") {
        // DischargePortToInlandRampTranMode = "Rail";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeDISC: availableTRansMode[1],
        }));
        // InlandRampToDestTranMode = "Truck";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeCYRAMP: availableTRansMode[0],
        }));
      } else {
        // DischargePortToInlandRampTranMode = "Truck";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeDISC: availableTRansMode[0],
        }));
      }
    } else if (state.slectedBLType.type === "CY-CY/Ramp") {
      setState((prev) => ({
        ...prev,
        selectedTrnsModeORGN: availableTRansMode[3],
      }));
      setState((prev) => ({ ...prev, selectedTrnsModeTRANS: "Sea" }));
    } else if (state.slectedBLType.type === "Door-CY/Ramp") {
      setState((prev) => ({
        ...prev,
        selectedTrnsModeORGN: availableTRansMode[3],
      }));
      setState((prev) => ({ ...prev, selectedTrnsModeTRANS: "Sea" }));
    } else if (state.slectedBLType.type === "Door-CY") {
      // OriginToLoadPortTranMode = "Barge";
      setState((prev) => ({
        ...prev,
        selectedTrnsModeORGN: availableTRansMode[2],
      }));
      // TransPortToDischargePortTranMode = "Sea";
      setState((prev) => ({ ...prev, selectedTrnsModeTRANS: "Sea" }));
    } else if (
      state.slectedBLType.type === "Door-Door" ||
      state.slectedBLType.type === "Door-CY/Ramp"
    ) {
      // OriginToLoadPortTranMode = "Barge";
      setState((prev) => ({
        ...prev,
        selectedTrnsModeORGN: availableTRansMode[2],
      }));
      if (state.selectedTransShpPort?.UNLoCode === undefined) {
        // LoadPortToTranPortMode = "Sea";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeLOAD: availableTRansMode[3],
        }));
      } else {
        // LoadPortToTranPortMode = "Sea";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeLOAD: availableTRansMode[3],
        }));

        // TransPortToDischargePortTranMode = "Sea";
        setState((prev) => ({ ...prev, selectedTrnsModeTRANS: "Sea" }));
      }
      if (state.selectedCyRamp?.UNLoCode !== "") {
        // DischargePortToInlandRampTranMode = "Rail";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeDISC: availableTRansMode[1],
        }));

        // InlandRampToDestTranMode = "Truck";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeCYRAMP: availableTRansMode[0],
        }));
      } else {
        // DischargePortToInlandRampTranMode = "Rail";
        setState((prev) => ({
          ...prev,
          selectedTrnsModeDISC: availableTRansMode[1],
        }));
      }
    }
  }, [
    availableTRansMode,
    state.selectedCyRamp?.UNLoCode,
    state.selectedTransShpPort?.UNLoCode,
    state.slectedBLType,
    state.addShipmentAction,
  ]);

  const gridReady = (params) => {
    gridApi.current = params.api;
    // this.gridColumnApi = params.columnApi;
    gridApi.current.sizeColumnsToFit();
  };

  const handleChangeChkbx = (event, pid, index) => {
    console.log(event.target.checked);
    if (event.target.checked) {
      setSelectedIDs([...selectedIds, pid]);
    } else {
      setSelectedIDs((prev) => {
        return prev.filter((el) => el !== pid);
      });
    }
    console.log(selectedIds);
    console.log(index);
    const newState = [...projChekbox];
    newState[index] = event.target.checked;
    setProjChekbox(newState);
  };
  const handleSudoImport = () => {
    setIsLoadingBar(true);
    const CopyShipments = window.structuredClone(shipments);
    // Exclude rows where status is 'Exitsting'
    const filteredShipments = CopyShipments.filter((shipment) => {
      return shipment.CO2EmissionFlag !== "Y";
    });

    if (filteredShipments.length > 0) {
      const promises = filteredShipments.map(async (element) => {
        element.MemberCode = MemberCode;
        element.LoadType = "LCL";
        element.NOR = "";
        delete element.GR_SH_ID;
        delete element.id;
        delete element.CO2Emission_in_grams;
        delete element.Emission_Cacl_Date;
        delete element.InsertDate;

        console.log(element);
        // AddShipmentAPICall(element);
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          onUploadProgress: (progressEvent) => {
            console.log(progressEvent);
            let progress = (progressEvent.loaded / progressEvent.total) * 100;
            setProgress(progress);
          },
        };

        await axios.post(
          `${APIBASE_URL}/GreenShipments?EditFlag=0&vType=C`,
          element,
          requestOptions
        );
      });

      Promise.allSettled(promises).then((result) => {
        result.forEach((res) => {
          console.log(res);
          if (res.status === "fulfilled") {
            console.log("Shipment added successfully!");
          } else if (res.status === "rejected") {
            console.log(res.reason);
          }
        });
        setShipments([]);
        setState({
          ...state,
          fileName: "Choose Excel File to Import Shipments",
        });
        setImportModalOpen(false);
        alert("Shipment added successfully!");
        setIsLoadingBar(false);
        getShipmentList();
        setProgress(0);
      });
    } else {
      alert("No Valid Shipment to be added. Please import correct data!");
      setShipments([]);
      setState({ ...state, fileName: "Choose Excel File to Import Shipments" });
      setIsLoadingBar(false);
    }

    const filteredShipments_failed = CopyShipments.filter((shipment) => {
      return shipment.FailReason !== "";
    });

    if (filteredShipments_failed.length > 0) {
      filteredShipments_failed.forEach((element) => {
        console.log(location);
        const failedElement = {
          MemberCode: MemberCode,
          ContainerNo: element.ContainerNo,
          SH_ID: element.id,
          ErrorNo: 555,
          ErrorDesc: element.FailReason,
          ModuleName:
            "CarbonNonNeutralizedShipments.js: Calculate emission and add shipment button",
        };
        // AddShipmentAPICall(element);
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        };

        axios
          .post(`${APIBASE_URL}/GreenErrorLog`, failedElement, requestOptions)
          .then((res) => {
            console.log(res);
          });
      });
    }
  };

  function updateData() {
    console.log("updateData");
    getShipmentList();
  }

  function getCurrentDateTimeString() {
    const currentDate = new Date();
    const MM = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // add 1 to get 1-based month and pad with 0 if necessary
    const DD = currentDate.getDate().toString().padStart(2, "0"); // pad with 0 if necessary
    const YYYY = currentDate.getFullYear().toString();
    const HH = currentDate.getHours().toString().padStart(2, "0"); // pad with 0 if necessary
    const mm = currentDate.getMinutes().toString().padStart(2, "0"); // pad with 0 if necessary
    const SS = currentDate.getSeconds().toString().padStart(2, "0"); // pad with 0 if necessary
    const currentDateTime = `${MM}${DD}${YYYY}${HH}${mm}${SS}`;
    return currentDateTime;
  }

  const handleSudoImport2 = () => {
    // setImportModalOpen(false);
    setAddShipmentSuccess(false);
    console.log(selectedIds);
    const uniqueselectedIds = [...new Set(selectedIds)];
    console.log(uniqueselectedIds);
    // setSelectedCertificates([]);
    const randomNumber = Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000;
    const totProjSelected = uniqueselectedIds.length;
    const CO2 = state.totalCO2Emision / totProjSelected;

    var OffsetCertificateIdVal = 0;
    if (uniqueselectedIds.length > 0) {
      const crtIds = [];
      uniqueselectedIds.forEach((id, i) => {
        const CBody = {
          OffsetCertificateTransactionId:
            "GREENGLOBALTRAN/" +
            getCurrentDateTimeString() +
            "/" +
            randomNumber +
            "/" +
            uniqueselectedIds[i],
          ProjectID: uniqueselectedIds[i],
          MemberCode: MemberCode,
          CO2: CO2, //[TotalamountOFCO2/NoOfProjectsSelected]
        };
        console.log(CBody);
        const requestOptionsProj = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        };
        setTimeout(() => {
          axios
            .post(
              `${APIBASE_URL}/GreenOffsetCertificate`,
              CBody,
              requestOptionsProj
            )
            .then((respC) => {
              console.log(respC);
              if (respC.status === 200) {
                console.log("Carbon Offset Certificate saved successfully!");
                console.log(respC.data);
                OffsetCertificateIdVal = respC?.data;
                crtIds.push(OffsetCertificateIdVal);
                console.log(OffsetCertificateIdVal);
                //Shipments
                selectedRows.forEach((shrow) => {
                  const Body = {
                    GR_SH_ID: shrow.GR_SH_ID,
                    ContainerNo: shrow.ContainerNo,
                    ProjectID: uniqueselectedIds[i],
                    DistPercentage: 1 / totProjSelected, // [(1/NoOfProjectsSelected)*100]
                    MemberCode: MemberCode,
                    CO2: shrow?.CO2Emission_in_grams / totProjSelected, //[TotalamountOFCO2/NoOfProjectsSelected]
                    OffsetCertificateId: OffsetCertificateIdVal,
                  };
                  console.log(Body);
                  const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                  };
                  axios
                    .post(
                      `${APIBASE_URL}/GreenOffsetShipment`,
                      Body,
                      requestOptions
                    )
                    .then((resp) => {
                      console.log(resp);
                      if (resp.status === 200) {
                        console.log("carbon offset done successfully!");
                        setEmitionOffsetModalOpen(false);
                        setOffsetSuccessModalOpen(true);
                        setAddShipmentSuccess(true);
                      } else {
                        console.log("carbon offset went wrong while adding!");
                        setEmitionOffsetModalOpen(false);
                        const failedElement = {
                          MemberCode: MemberCode,
                          ContainerNo: shrow.ContainerNo,
                          SH_ID: shrow.GR_SH_ID,
                          ErrorNo: resp.status,
                          ErrorDesc: "carbon offset went wrong while adding",
                          ModuleName:
                            "CarbonNonNeutralizedShipments.js: Carbon Offset Certificate save",
                        };
                        const requestOptions = {
                          method: "POST",
                          headers: { "Content-Type": "application/json" },
                        };

                        axios
                          .post(
                            `${APIBASE_URL}/GreenErrorLog`,
                            failedElement,
                            requestOptions
                          )
                          .then((res) => {
                            console.log(res);
                          });
                      }
                    });
                });
              } else {
                console.log("Carbon Offset Certificate saving error!");
                setEmitionOffsetModalOpen(false);
                const failedElement = {
                  MemberCode: MemberCode,
                  ContainerNo: "",
                  SH_ID: "",
                  ErrorNo: respC.status,
                  ErrorDesc: "Carbon Offset Certificate saving error",
                  ModuleName:
                    "CarbonNonNeutralizedShipments.js: Carbon Offset Certificate save",
                };
                const requestOptions = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                };

                axios
                  .post(
                    `${APIBASE_URL}/GreenErrorLog`,
                    failedElement,
                    requestOptions
                  )
                  .then((res) => {
                    console.log(res);
                  });
              }
              // setSelectedCertificates(crtIds);
            });
        }, 2000); // 2000 milliseconds = 2 seconds
      });
    }
    // setSudoShipments(shipments);
  };
  const handleImport = ($event) => {
    setIsLoadingCircle(true);
    const files = $event.target.files;
    console.log(files[0].name);

    setState({ ...state, fileName: files[0].name });
    setImportSuccess(true);
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const Key = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const bodyData = JSON.stringify({ Key });
        console.log(bodyData);
        //const JSONBody =JSON.stringify(bodyData);
        const PostOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
        };
        axios
          .post(`${APIBASE_URL}/GreenShipments?Temp=2`, bodyData, PostOptions)
          .then((respEx) => {
            console.log(respEx.data);
            if (respEx.status === 200) {
              const shipmnts = respEx.data;
              const ImpShpments = shipmnts.map((el, i) => {
                return {
                  ...el,
                  id: i + 1,
                };
              });
              setShipments(ImpShpments);
              setIsLoadingCircle(false);
            } else {
              alert(
                "Wrong excel template choosen! Please choose the excel file with correct template!"
              );
            }
          });
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const GetRowClassImport = (params) => {
    // console.log(params.row?.CO2EmissionFlag);

    const status = params.row?.CO2EmissionFlag;
    const classes = useStyles();

    if (status === "Y") {
      return classes.incorrectRow;
    } else {
      return classes.correctRow;
    }
  };
  const handleImpModalOpen = () => {
    setImportModalOpen(true);
  };
  const handleImpModalClose = () => {
    setImportModalOpen(false);
  };

  const handleExpModalOpen = () => {
    setExportModalOpen(true);
  };
  const handleExpModalClose = (e, reason) => {
    console.log(reason);
    if (reason !== "backdropClick") {
      setExportModalOpen(false);
      clearForm();
    }
  };
  const handleEmitionOffsetModalOpen = () => {
    setEmitionOffsetModalOpen(true);
  };
  const handleEmitionOffsetModalClose = () => {
    setEmitionOffsetModalOpen(false);
  };
  const handleOffsetSuccessModalClose = () => {
    setOffsetSuccessModalOpen(false);
  };
  // const handleOffsetCerticateModalClose = () => {
  //   setOffsetCerticateModalOpen(false);
  // };
  // const handleViewCerticateModalClose = () => {
  //   setViewCerticateModalOpen(false);
  // };
  // const handleViewCerticateModalOpen = () => {
  //   setViewCerticateModalOpen(true);
  //   // setOffsetSuccessModalOpen(false);
  // };
  const handleOffsetCerticateModalOpen = () => {
    //setOffsetCerticateModalOpen(true);
    setOffsetSuccessModalOpen(false);
    localStorage.setItem("PageHeader", "Shipment/CN");
    // navigate("/" + "Shipment" + "/" + "CN");
    navigate("/Shipment/CN");
  };
  // const handleChangeContainerSize = (event) => {
  //   setContainerSize(event.target.value);
  // };
  const handleChangeTransshipment = (event) => {
    setTransShipments(event.target.checked);
    console.log(event.target.checked);
  };

  const handleChangeNor40 = (event) => {
    setNor40(event.target.checked);
    console.log(event.target.checked);
  };
  const handleContainerNo = (e) => {
    setState({ ...state, containerNo: e.target.value });
    if (e.target.value !== "") {
      setErrCntnr(false);
    }
  };
  const handlePieces = (e) => {
    setState({ ...state, piece: e.target.value });
    if (e.target.value !== "") {
      setErrPiece(false);
    }
  };
  const handleWeight = (e) => {
    setState({ ...state, weight: e.target.value });
    if (e.target.value !== "") {
      setErrWght(false);
    }
  };
  const handleVolume = (e) => {
    setState({ ...state, volume: e.target.value });
    if (e.target.value !== "") {
      setErrVolm(false);
    }
  };
  const handleBlNo = (e) => {
    setState({ ...state, blnumber: e.target.value });
    if (e.target.value !== "") {
      setErrBlNo(false);
    }
  };
  const handlevessel = (e) => {
    setState({ ...state, vessel: e.target.value });
  };
  // const handlevoyage = (e) => {
  //   setState({ ...state, voyage: e.target.value });
  // };
  const handletransShipvessel = (e) => {
    setState({ ...state, transShipvessel: e.target.value });
  };

  const handleDateChange = (date) => {
    setState({ ...state, selectedPOLDate: date });
  };
  const onSelectionChanged = useCallback(() => {
    var selectedRows = gridRef.current.api.getSelectedRows();
    console.log(selectedRows);
    setSelectedRows(selectedRows);
    var totalCO2Emsn = 0;
    selectedRows.forEach(function (selectedRow, index) {
      totalCO2Emsn += selectedRow.CO2Emission_in_grams;
    });
    console.log(totalCO2Emsn);

    setState((prev) => ({ ...prev, totalCO2Emision: totalCO2Emsn }));
  }, []);

  const addShipment = () => {
    console.log(state.selectedCarrier);
    if (state.selectedCarrier === undefined) {
      setErrCR(true);
    }
    if (state.containerNo === "") {
      setErrCntnr(true);
    }
    // if (state.piece === "") {
    //   setErrPiece(true);
    // }
    if (state.weight <= 0 || state.weight === "") {
      setErrWght(true);
    }
    // if (state.volume === "") {
    //   setErrVolm(true);
    // }
    if (state.blnumber === "") {
      setErrBlNo(true);
    }
    if (state.containerSize.Size === "") {
      setErrCntSz(true);
    }
    if (state.slectedBLType.type === "") {
      setErrBL(true);
    }
    if (state.selectedOrigin === undefined) {
      setErrOrgn(true);
    }
    if (state.selectedDestination === undefined) {
      setErrDest(true);
    }
    if (
      state.slectedBLType.type === "Door-Door" ||
      state.slectedBLType.type === "Door-CY"
    ) {
      if (state.selectedLoadPort === undefined) {
        setErrLoadPrt(true);
      }
    }
    if (
      state.slectedBLType.type === "Door-Door" ||
      state.slectedBLType.type === "CY-Door" ||
      state.slectedBLType.type === "CY-CY/Ramp" ||
      state.slectedBLType.type === "Door-CY/Ramp"
    ) {
      if (state.selectedDiscPort === undefined) {
        setErrDiscPrt(true);
      }
    }
    setAddShipmentSuccess(false);
    const shipmentObj = {
      MemberCode: MemberCode,
      Carrier: state.selectedCarrier.Carrier,
      ContainerNo: state.containerNo,
      ContainerSize: nor40 ? "40NOR" : state.containerSize.Size, /// Rupam: need to change
      Vessel: state.vessel,
      Voyage: state.voyage,
      VesselIMO: "",
      TranshipVesselName: state.transShipvessel,
      POLDate: state.selectedPOLDate,
      BLType: state.slectedBLType.type,
      OriginLocCode: state.selectedOrigin?.UNLoCode,
      LoadPortLocCode:
        state.slectedBLType.type === "Door-Door" ||
        state.slectedBLType.type === "Door-CY"
          ? state.selectedLoadPort === undefined
            ? ""
            : state.selectedLoadPort?.UNLoCode
          : "",
      TransPortLocCode: transshipments
        ? state.selectedTransShpPort === undefined
          ? ""
          : state.selectedTransShpPort?.UNLoCode
        : "",
      DischargePortLocCode:
        state.slectedBLType.type === "Door-Door" ||
        state.slectedBLType.type === "CY-Door" ||
        state.slectedBLType.type === "CY-CY/Ramp" ||
        state.slectedBLType.type === "Door-CY/Ramp"
          ? state.selectedDiscPort === undefined
            ? ""
            : state.selectedDiscPort?.UNLoCode
          : "",
      InlandCYRampCode:
        state.slectedBLType.type === "Door-Door" ||
        state.slectedBLType.type === "CY-Door"
          ? state.selectedCyRamp === undefined
            ? ""
            : state.selectedCyRamp?.UNLoCode
          : "",
      DestLocCode: state.selectedDestination?.UNLoCode,
      Origin: state.selectedOrigin?.City + ", " + state.selectedOrigin?.Country,
      LoadPort:
        state.slectedBLType.type === "Door-Door" ||
        state.slectedBLType.type === "Door-CY"
          ? state.selectedLoadPort === undefined
            ? ""
            : state.selectedLoadPort?.City +
              ", " +
              state.selectedLoadPort?.Country
          : "",
      TransPort: transshipments
        ? state.selectedTransShpPort === undefined
          ? ""
          : state.selectedTransShpPort?.City +
            ", " +
            state.selectedTransShpPort?.Country
        : "",
      DischargePort:
        state.slectedBLType.type === "Door-Door" ||
        state.slectedBLType.type === "CY-Door" ||
        state.slectedBLType.type === "CY-CY/Ramp" ||
        state.slectedBLType.type === "Door-CY/Ramp"
          ? state.selectedDiscPort === undefined
            ? ""
            : state.selectedDiscPort?.City +
              ", " +
              state.selectedDiscPort?.Country
          : "",
      InlandCYRamp:
        state.slectedBLType.type === "Door-Door" ||
        state.slectedBLType.type === "CY-Door"
          ? state.selectedCyRamp === undefined
            ? ""
            : state.selectedCyRamp?.City + ", " + state.selectedCyRamp?.Country
          : "",
      Destination:
        state.selectedDestination?.City +
        ", " +
        state.selectedDestination?.StateProvince +
        ", " +
        state.selectedDestination?.Country,
      OriginToLoadPortTranMode: state.selectedTrnsModeORGN?.mode,
      // state.slectedBLType.type === "Door-Door" ||
      // state.slectedBLType.type === "Door-CY" ||
      // state.slectedBLType.type === "CY-Door" ||
      // state.slectedBLType.type === "CY-CY/Ramp" ||
      // state.slectedBLType.type === "Door-CY/Ramp"
      //   ? state.selectedTrnsModeORGN?.mode
      //   : "",
      LoadPortToTransPortTranMode: "sea",
      TransPortToDischargePortTranMode: transshipments ? "sea" : "",
      DischargePortToInlandRampTranMode:
        state.slectedBLType.type === "Door-Door" ||
        state.slectedBLType.type === "CY-Door" ||
        state.slectedBLType.type === "CY-CY/Ramp" ||
        state.slectedBLType.type === "Door-CY/Ramp"
          ? state.selectedTrnsModeDISC?.mode
          : "",
      InlandRampToDestTranMode:
        state.slectedBLType.type === "Door-Door" ||
        state.slectedBLType.type === "CY-Door"
          ? state.selectedTrnsModeCYRAMP?.mode
          : "",
      CO2EmissionFlag: "N",
      SCAC: state.selectedCarrier.SCAC,
      LoadType: "LCL",
      NoOfCartons: state.piece ? state.piece : 0,
      TotalWeight: state.weight,
      TotalVolume: state.volume ? state.volume : 0,
      BLNo: state.blnumber,
      NOR: "",
      OriginAddress: "",
      OriginZIPCode: "",
      DestinationAddress: "",
      DestinationZIPCode: "",
      Comment1: state.comment1,
      Comment2: state.comment2,
    };
    console.log(shipmentObj);

    AddShipmentAPICall(shipmentObj);
  };

  const AddShipmentAPICall = (shipmentObj) => {
    console.log(shipmentObj);
    console.log(state.addShipmentAction);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    //      http://api.greenabl.us/api/GreenShipments?EditFlag=1
    if (state.addShipmentAction === "ADD") {
      console.log(Date.now());
      axios
        .post(
          `${APIBASE_URL}/GreenShipments?EditFlag=0&vType=C`,
          shipmentObj,
          requestOptions
        )
        .then((resp) => {
          console.log(resp);
          if (resp.status === 200) {
            console.log("Shipment added successfully!");
            setSuccMsg("Shipment added successfully!");
            clearForm();
            handleClick();
            setAddShipmentSuccess(true);
            handleExpModalClose();
          } else {
            console.log("Something went wrong while Shipment was added!");
            handleExpModalClose();
            const failedElement = {
              MemberCode: MemberCode,
              ContainerNo: "",
              SH_ID: "",
              ErrorNo: resp.status,
              ErrorDesc: "Something went wrong while Shipment was added",
              ModuleName: "UserorifileContent.js: edit shipment",
            };
            const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
            };

            axios
              .post(
                `${APIBASE_URL}/GreenErrorLog`,
                failedElement,
                requestOptions
              )
              .then((res) => {
                console.log(res);
              });
          }
        });
    } else if (state.addShipmentAction === "Edit") {
      shipmentObj.GR_SH_ID = state.shipmentIdToEdit;
      axios
        .post(
          `${APIBASE_URL}/GreenShipments?EditFlag=1&vType=C`,
          shipmentObj,
          requestOptions
        )
        .then((resp) => {
          console.log(resp);
          if (resp.status === 200) {
            console.log("Shipment edited successfully!");
            setSuccMsg("Shipment edited successfully!");
            clearForm();
            handleClick();
            setAddShipmentSuccess(true);
            handleExpModalClose();
          } else {
            console.log("Something went wrong while Editing Shipment!");
            handleExpModalClose();
            const failedElement = {
              MemberCode: MemberCode,
              ContainerNo: "",
              SH_ID: "",
              ErrorNo: resp.status,
              ErrorDesc: "Something went wrong while Editing Shipment",
              ModuleName: "UserorifileContent.js: edit shipment",
            };
            const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
            };

            axios
              .post(
                `${APIBASE_URL}/GreenErrorLog`,
                failedElement,
                requestOptions
              )
              .then((res) => {
                console.log(res);
              });
          }
        });
    }
  };

  const clearForm = () => {
    setState({
      ...state,
      selectedCarrier: undefined,
      selectedOrigin: undefined,
      selectedDestination: undefined,
      selectedLoadPort: undefined,
      selectedDiscPort: undefined,
      selectedTransShpPort: undefined,
      selectedCyRamp: undefined,
      selectedPlaceofOrigin: undefined,
      originValue: "",
      loadPortValue: "",
      discPortValue: "",
      transShpPortValue: "",
      cyRampValue: "",
      destinationValue: "",
      originPlaceValue: "",
      carrierValue: "",
      dischargePlaceValue: "",
      selectedTrnsModeORGN: availableTRansMode[3],
      selectedTrnsModeLOAD: availableTRansMode[3],
      selectedTrnsModeDISC: availableTRansMode[1],
      selectedTrnsModeCYRAMP: availableTRansMode[0],
      selectedTrnsModeTRANS: "Sea",
      availableDestinations: [{}],
      availablePortofDischarge: [{}],
      availableCarriers: [{}],
      slectedBLType: availableBlType[2],
      containerNo: "",
      containerSize: availableContSize[2],
      vessel: "",
      voyage: "",
      transMode: "",
      transShipvessel: "",
      selectedPOLDate: moment().format("L"),
      addShipmentAction: "ADD",
      shipmentIdToEdit: "",
      volume: 0,
      weight: 0,
      piece: 0,
      blnumber: "",
      OriginAddress: "",
      OriginZIPCode: "",
      DestinationAddress: "",
      DestinationZIPCode: "",
      Comment1: "",
      Comment2: "",
    });
    setNor40(false);
    setTransShipments(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (emitionOffsetModalOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [emitionOffsetModalOpen]);

  const columns = [
    {
      field: "Carrier",
      headerName: "Carrier",
      width: 140,
      headerCheckboxSelection: true,
      headerCheckboxSelectionCurrentPageOnly: true,
      headerCheckboxSelectionFilteredOnly:true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
    },
    // {
    //   field: "BLType",
    //   headerName: "BL Type",
    //   width: 100,
    // },
    {
      field: "ContainerNo",
      headerName: "Container #",
      width: 135,
    },
    {
      field: "NoOfCartons",
      headerName: "# Cartons",
      width: 100,
    },
    {
      field: "TotalWeight",
      headerName: "Weight",
      width: 90,
    },
    {
      field: "TotalVolume",
      headerName: "Volume",
      width: 90,
    },
    {
      field: "Origin",
      headerName: "Origin",
      width: 180,
    },
    {
      field: "Destination",
      headerName: "Destination",
      width: 180,
    },
    {
      field: "CO2Emission_in_grams",
      headerName: "CO2e(MTon)",
      width: 115,
    },
    {
      field: "Vessel",
      headerName: "Vessel",
      width: 200,
    },
    {
      field: "InsertDate",
      headerName: "Insert Date",
      width: 140,
      valueFormatter: (params) => {
        const dt = new Date(params.value);

        return new Intl.DateTimeFormat("en-US").format(dt);
      },
    },
    {
      field: "Comment1",
      headerName: "Comment 1",
      width: 140,
    },
    {
      field: "Comment2",
      headerName: "Comment 2",
      width: 140,
    },
    {
      field: "GR_SH_ID",
      headerName: " ",

      cellRenderer: editButton,
      cellRendererParams: {
        update: updateData,
        setExportModalOpen,
        noncarbonNutralisedShipemnts,
        setState,
        availableAllOriginDestinations,
        availableBlType,
        availableCarriers,
        availableContSize,
        availableTRansMode,
        setTransShipments,
        setNor40,
      },
      pinned: "right",
      lockPosition: "left",
      width: 60,
    },
    {
      field: "GR_SH_ID",
      headerName: " ",
      cellRenderer: DeleteCellRenderer,
      cellRendererParams: {
        update: updateData,
      },
      pinned: "right",
      lockPosition: "right",
      width: 60,
    },
  ];
  const datacolumns = [
    {
      field: "Carrier",
      headerName: "Carrier",
      width: 150,
    },
    {
      field: "BLNo",
      headerName: "BL No",
      width: 150,
    },
    {
      field: "BLType",
      headerName: "BLType",
      width: 120,
      hide: true,
    },
    {
      field: "ContainerNo",
      headerName: "Container#",
      width: 150,
    },
    {
      field: "ContainerSize",
      headerName: "Size",
      width: 150,
      hide: true,
    },
    {
      field: "NoOfCartons",
      headerName: "Piece",
      width: 150,
    },
    {
      field: "TotalWeight",
      headerName: "Weight",
      width: 150,
    },
    {
      field: "TotalVolume",
      headerName: "Volume",
      width: 150,
    },
    {
      field: "Vessel",
      headerName: "Vessel",
      width: 140,
    },
    {
      field: "Origin",
      headerName: "Origin",
      width: 160,
    },
    {
      field: "LoadPort",
      headerName: "POL",
      width: 160,
    },
    {
      field: "TransPort",
      headerName: "ViaPort",
      width: 160,
    },
    {
      field: "DischargePort",
      headerName: "DischargePort",
      width: 160,
    },
    {
      field: "InlandCYRamp",
      headerName: "InlandCYRamp",
      width: 160,
    },
    {
      field: "Destination",
      headerName: "Destination",
      width: 160,
    },
    {
      field: "OriginToLoadPortTranMode",
      headerName: "Transportation Mode",
      width: 100,
      valueGetter: (params) =>
        `${params.row?.OriginToLoadPortTranMode || ""} ${
          params.row?.InlandRampToDestTranMode || ""
        } - ${params.row?.DischargePortToInlandRampTranMode || ""}`,
    },
    {
      field: "FailReason",
      headerName: "Comments",
      width: 350,
    },
  ];

  const [open, setOpen] = useState(false);
  const [succMsg, setSuccMsg] = useState("");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const [deleteConfirmText, setDeleteConfirmText] = useState(
    "Are you sure to delete multiple shipments at a time?"
  );
  const [openDelete, setOpenDelete] = useState(false);
  const [statQuo, setStatQuo] = useState("unconfirmed");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseDelete = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setDeleteConfirmText("Are you sure to delete multiple shipments at a time?")
    setStatQuo("unconfirmed");
    setOpenDelete(false);
  };
  const DeleteAllSelected = (status) => {
    var selectedRows = gridRef.current.api.getSelectedRows();
    if (status === "unconfirmed") {
      setStatQuo("semiconfirmed");
      setOpenDelete(true);
    } else if (status === "semiconfirmed") {
      setOpenDelete(false);
      const thisMany = selectedRows.length;
      setDeleteConfirmText(
        `Please confirm you are sure to delete ${thisMany} Shipments at one go? This action can not be undone.`
      );
      setStatQuo("confirmed");
      setOpenDelete(true);
    } else {
      //console.log(selectedRows);
      const IdsToDelete = selectedRows.map((row) => {
        return row.GR_SH_ID;
      });
      //DeleteCellRenderer(IdsToDelete);
      console.log(IdsToDelete);
      let count = 0;
      IdsToDelete.forEach((id) => {
        // http://api.greenabl.us/api/GreenShipments?ShipmentId=5
        axios
          .post(`${APIBASE_URL}/GreenShipments?ShipmentId=${id}`)
          .then((resp) => {
            console.log(resp);
            if (resp.data === "Deleted") {
              count = count + 1;
            }

            console.log(count);
            if (count === IdsToDelete.length) {
              setOpenDelete(false);
              updateData();
            }
          });
      });
    }
  };


  return (
    <>
      <Card className={classes.GridContainer}>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 10,
          }}
        >
          <div>
            <Typography variant="h5">
              Carbon Non Neutralized Shipments - LCL
            </Typography>
            <Tooltip title="Download Excel Template">
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleDownloadTemplate}
                style={{ marginTop: 10 }}
              >
                <CloudDownload></CloudDownload>
              </Button>
            </Tooltip>
          </div>

          <div>
            <Tooltip title="Import Shipments">
              <Button
                size="small"
                variant="contained"
                color="primary"
                style={{ margin: 7 }}
                onClick={handleImpModalOpen}
              >
                <Input></Input>
              </Button>
            </Tooltip>
            <Tooltip title="Add LCL Shipment">
              <Button
                size="small"
                variant="contained"
                color="primary"
                style={{ margin: 7 }}
                onClick={handleExpModalOpen}
              >
                <PostAdd></PostAdd>
              </Button>
            </Tooltip>
            <Tooltip title="Select and Offset Shipment">
              <span>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ margin: 7 }}
                  onClick={handleEmitionOffsetModalOpen}
                  disabled={selectedRows.length <= 0}
                >
                  <AssignmentTurnedIn></AssignmentTurnedIn>
                </Button>
              </span>
            </Tooltip>
            <Tooltip title="View Shipment">
              <span>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ margin: 7 }}
                  onClick={viewShipemntDetails}
                  disabled={selectedRows.length !== 1}
                >
                  <Visibility></Visibility>
                </Button>
              </span>
            </Tooltip>
            <Tooltip title="Download">
              <Button
                size="small"
                variant="contained"
                color="primary"
                style={{ margin: 7 }}
                onClick={handleDownloadCNNS}
                disabled={noncarbonNutralisedShipemnts.length === null}
              >
                <SystemUpdateAltIcon></SystemUpdateAltIcon>
              </Button>
            </Tooltip>
            <Tooltip title="Delete All">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => DeleteAllSelected("unconfirmed")}
                  disabled={selectedRows.length <= 1}
                >
                  <Delete></Delete>
                </Button>
              </Tooltip>
          </div>
        </CardContent>

        <CardContent>
          <div className="example-header">
            Page Size:
            <select
              onChange={onPageSizeChanged}
              id="page-size"
              style={{ marginLeft: 10 }}
            >
              <option value="25">25</option>
              <option value="50" selected>50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
          <div
            style={{ height: 475, maxWidth: "100%", overflowX: "auto" }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              ref={gridRef}
              columnDefs={columns}
              rowData={noncarbonNutralisedShipemnts}
              rowSelection={"multiple"}
              rowMultiSelectWithClick={true}
              onSelectionChanged={onSelectionChanged}
              defaultColDef={{
                resizable: true,
                sortable: true,
                background: "#f0f0f5",
              }}
              pagination={true}
              paginationPageSize={50}
              onGridReady={gridReady}
              // frameworkComponents={{
              //   checkCellRendere: CheckRenderer,
              //   checkArrivalRenderer: arrivalNoticeStatus,
              // }}
            ></AgGridReact>
          </div>
        </CardContent>

        <>
          <Modal
            open={importModalOpen}
            onClose={handleImpModalClose}
            aria-labelledby="excel-import-modal"
            aria-describedby="excel-import-modal-description"
          >
            <div
              style={modalStyle}
              className={`${classes.paper} ${classes.largeWidth}`}
            >
              <h2 id="simple-modal-title" style={{ textAlignLast: "center" }}>
                Import Shipments
              </h2>
              <CardContent>
                <div style={{ height: 450, width: "100%" }}>
                  <div className="custom-file">
                    <label for="fileInput" className={classes.btn}>
                      {state.fileName}
                    </label>
                    <input
                      type="file"
                      name="file"
                      className="custom-file-input"
                      id="fileInput"
                      required
                      style={{ visibility: "hidden", paddingBottom: 15 }}
                      onChange={handleImport}
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    />
                    {isLoadingCircle && <CircularProgress color="secondary" />}
                  </div>

                  <DataGrid
                    rows={shipments}
                    columns={datacolumns}
                    getRowClassName={GetRowClassImport}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    // checkboxSelection
                    disableSelectionOnClick
                  />
                </div>
              </CardContent>
              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 10,
                  marginTop: "16px",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSudoImport}
                  style={{ width: 220, textTransform: "capitalize" }}
                  disabled={!importSuccess}
                >
                  Calculate Emission &amp; Add
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ width: 100, textTransform: "capitalize" }}
                  onClick={() => {
                    setImportModalOpen(false);
                    setShipments([]);
                    setState({
                      ...state,
                      fileName: "Choose Excel File to Import Shipments",
                    });
                    setIsLoadingCircle(false);
                    setIsLoadingBar(false);
                  }}
                >
                  Cancel
                </Button>
                {isLoadingBar && (
                  <LinearProgressWithLabel
                    value={progress}
                    className={classes.progressWidth}
                  />
                )}
              </CardContent>
            </div>
          </Modal>
          <div>
            <Dialog
              open={emitionOffsetModalOpen}
              onClose={handleEmitionOffsetModalClose}
              scroll="paper"
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
              fullWidth={true}
              maxWidth={"lg"}
            >
              <DialogTitle id="scroll-dialog-title">
                Select Project(s) to Offset
              </DialogTitle>
              {/* <CloseButton onClose={handleEmitionOffsetModalClose} /> */}
              <DialogContent dividers>
                <DialogContentText
                  id="scroll-dialog-description"
                  ref={descriptionElementRef}
                  tabIndex={-1}
                >
                  <Card>
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 8,
                        marginTop: "12px",
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                        {projectListDetails &&
                          projectListDetails.map((projDtls, index) => (
                            <Grid item sm={3} key={index}>
                              <Card
                                style={{
                                  minWidth: 130,
                                  maxWidth: 240,
                                  maxHeight: 540,
                                  minHeight: 370,
                                }}
                              >
                                <CardHeader
                                  style={{
                                    maxHeight: 115,
                                    minHeight: 100,
                                    verticalAlign: "top",
                                  }}
                                  title={projDtls.PName.substring(0, 35)}
                                  // subheader={projDtls.ProjectLocation.split(/[\s,]+/).pop()}
                                  subheader={projDtls.ProjectLocation.substring(
                                    projDtls.ProjectLocation.indexOf(",") + 1,
                                    100
                                  )}
                                  avatar={
                                    <Tooltip title={projDtls.PName}>
                                      <Avatar
                                        aria-label="recipe"
                                        className={classes.avatar}
                                      >
                                        {projDtls.PName[0]}
                                      </Avatar>
                                    </Tooltip>
                                  }
                                />

                                <CardMedia
                                  className={classes.media}
                                  image={projDtls.imgUrl}
                                />
                                <CardContent
                                  style={{ maxHeight: 125, minHeight: 125 }}
                                >
                                  <Typography variant="body2">
                                    Total CO<sub>2</sub>e for Offset:{" "}
                                    {projDtls.PAvailable_carbon_in_kg} MTon
                                  </Typography>
                                  <Typography variant="body2">
                                    Offset Cost per MTon: $
                                    {(projDtls?.Pcost_per_kg_carbon_in_usd_cents *
                                      1000) /
                                      100}
                                  </Typography>
                                  <FormGroup
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignContent: "space-between",
                                    }}
                                  >
                                    <FormControlLabel
                                      key={projDtls.PId}
                                      control={
                                        <Checkbox
                                          checked={projChekbox[index]}
                                          onChange={(e) =>
                                            handleChangeChkbx(
                                              e,
                                              projDtls.PId,
                                              index
                                            )
                                          }
                                          name={projDtls.PId}
                                        />
                                      }
                                    />
                                  </FormGroup>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                      </Grid>

                      <div style={{ padding: "10px", textAlign: "center" }}>
                        <h3
                          id="simple-modal-title"
                          style={{ textAlignLast: "center" }}
                        >
                          Total CO<sub>2</sub>e for Offset:{" "}
                          {parseFloat(state.totalCO2Emision.toFixed(2))} MTon
                        </h3>
                        <Checkbox
                          checked={state.isChecked}
                          name="Agreement"
                          onClick={() =>
                            setState({ ...state, isChecked: !state.isChecked })
                          }
                        />
                        This is to confirm that you are agreeing to offset the
                        amount of carbon against the selected shipments.
                        <br />
                        <h4 style={{ textAlign: "center" }}>
                          Note: If multiple projects are selected, purchase will
                          be prorated by MTon(weight) among selected projects
                        </h4>
                      </div>
                      {/*  <h4>
                      CO<sub>2</sub> Offsetting for selected shipments 60%
                    </h4>
                    <LinearProgressWithLabel
                      value={progress}
                      style={{ width: 250 }}
                    /> */}
                    </CardContent>
                  </Card>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSudoImport2}
                  style={{
                    width: 100,
                    textTransform: "capitalize",
                    marginRight: 15,
                  }}
                  disabled={!state.isChecked || selectedIds.length === 0}
                >
                  Proceed
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ width: 100, textTransform: "capitalize" }}
                  onClick={() => {
                    setEmitionOffsetModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          {/* <Modal
            open={emitionOffsetModalOpen}
            onClose={handleEmitionOffsetModalClose}
            aria-labelledby="emition-offset-modal"
            aria-describedby="emition-offset-modal-description"
          >
            <div
              style={modalStyle}
              className={`${classes.paper} ${classes.largeWidth}  ${classes.height}`}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2 id="simple-modal-title" style={{ textAlignLast: "center" }}>
                  Select Project(s) to Offset
                </h2>
                <CloseButton onClose={handleEmitionOffsetModalClose} />
              </div>
  
              <div style={{ position: "fixed" }}></div>
            </div>
          </Modal> */}
          <Modal
            open={OffsetSuccessModalOpen}
            onClose={handleOffsetSuccessModalClose}
            aria-labelledby="emition-offset-success-modal"
            aria-describedby="emition-offset-success-modal-description"
          >
            <div
              style={modalStyle}
              className={`${classes.paper} ${classes.largeWidth}`}
            >
              <h2 id="simple-modal-title" style={{ textAlignLast: "center" }}>
                Offset Shipments
              </h2>

              <CardContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 10,
                  marginTop: "16px",
                }}
              >
                <h2
                  id="transition-modal-description"
                  style={{ textAlign: "center" }}
                >
                  Congratulations!! You have successfully offset the amount of
                  carbon against the selected shipments!!
                </h2>
                <h2
                  id="transition-modal-description"
                  style={{ textAlign: "center" }}
                >
                  Click{" "}
                  <Link
                    href="#"
                    onClick={handleOffsetCerticateModalOpen}
                    style={{ color: "blue" }}
                  >
                    here{" "}
                  </Link>{" "}
                  to View and Download the Carbon Offset Certificates for the
                  Neutralized Shipments
                </h2>

                <h3 style={{ textAlign: "center" }}>
                  Note: Certificates can be download anytime from Greenabl web
                  application
                </h3>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  style={{ width: 100, textTransform: "capitalize" }}
                  onClick={handleOffsetSuccessModalClose}
                >
                  Okay
                </Button>
              </CardContent>
            </div>
          </Modal>
          <Modal
            open={exportModalOpen}
            onClose={(e, reason) => {
              handleExpModalClose(e, reason);
            }}
            aria-labelledby="shipment-export-modal"
            aria-describedby="shipment-export-modal-description"
          >
            <div
              style={modalStyle}
              className={`${classes.paper} ${classes.mediumWidth}`}
            >
              <h2 id="simple-modal-title">
                {state.addShipmentAction +
                  " Shipment LCL [Automated Emission Calculation]"}
              </h2>

              <Grid
                container
                spacing={3}
                style={{ border: "2px solid #f3edea" }}
              >
                <Grid item sm={12} lg={12} xl={12} style={{ padding: 8 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <Autocomplete
                        id="gem-carr-select"
                        size="small"
                        fullWidth
                        disableClearable
                        options={availableCarriers}
                        getOptionLabel={(option) =>
                          option.Carrier + " - " + option.SCAC
                        }
                        renderOption={(option) => (
                          <React.Fragment>
                            {option.Carrier + " - " + option.SCAC}
                          </React.Fragment>
                        )}
                        style={{
                          display: "inline-flex",
                          backgroundColor: "#f3edea",
                        }}
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Carrier"
                            variant="outlined"
                            required
                            error={errCR}
                            helperText={errCR ? "Carrer can not be blank" : ""}
                          />
                        )}
                        value={state.selectedCarrier}
                        onChange={(event, nwValue) => {
                          setState({ ...state, selectedCarrier: nwValue });
                          setErrCR(errCR ? !errCR : errCR);
                        }}
                        color="primay"
                        classes={{ option: classes.option }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <FormControl>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          size="small"
                          style={{
                            display: "inline-flex",
                            backgroundColor: "#EDEAE8",
                            borderRadius: "4px",
                          }}
                          required
                          value={state.containerNo}
                          label="Container No"
                          onChange={handleContainerNo}
                          error={errCntnr}
                          helperText={
                            errCntnr ? "Container number can not be blank" : ""
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <Autocomplete
                        id="gem-contSize-select"
                        size="small"
                        fullWidth
                        disableClearable
                        options={availableContSize}
                        getOptionLabel={(option) => option.Size}
                        renderOption={(option) => (
                          <React.Fragment>{option.Size}</React.Fragment>
                        )}
                        value={state.containerSize}
                        style={{
                          display: "inline-flex",
                          backgroundColor: "#f3edea",
                        }}
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Cont. Size"
                            variant="outlined"
                            required
                            error={errCntSz}
                            helperText={
                              errCntSz ? "Container size can not be blank" : ""
                            }
                          />
                        )}
                        // onChange={onSelectCarrier}
                        onChange={(event, nwValue) => {
                          setState({ ...state, containerSize: nwValue });
                          if (
                            nwValue.Size !== "40GP" &&
                            nwValue.Size !== "40HC"
                          ) {
                            setNor40(false);
                          }
                          setErrCntSz(errCntSz ? !errCntSz : errCntSz);
                        }}
                        color="primay"
                        classes={{ option: classes.option }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={
                              state.containerSize.Size === "40GP"
                                ? false
                                : state.containerSize.Size === "40HC"
                                ? false
                                : true
                            }
                            checked={nor40}
                            onChange={handleChangeNor40}
                            name="NOR"
                            style={{ marginLeft: -16, paddingRight: 0 }}
                          />
                        }
                        label="NOR"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <Autocomplete
                        id="gem-bltype-select"
                        size="small"
                        fullWidth
                        disableClearable
                        options={availableBlType}
                        getOptionLabel={(option) => option.type}
                        renderOption={(option) => (
                          <React.Fragment>{option.type}</React.Fragment>
                        )}
                        value={state.slectedBLType}
                        style={{
                          display: "inline-flex",
                          backgroundColor: "#f3edea",
                        }}
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Shipmnt Type"
                            variant="outlined"
                            required
                            error={errBL}
                            helperText={
                              errBL ? "Shipment type can not be blank" : ""
                            }
                          />
                        )}
                        // onChange={onSelectCarrier}
                        onChange={(event, nwValue) => {
                          setState({ ...state, slectedBLType: nwValue });
                          setErrBL(errBL ? !errBL : errBL);
                        }}
                        color="primay"
                        classes={{ option: classes.option }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <FormControl>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          size="small"
                          style={{
                            display: "inline-flex",
                            backgroundColor: "#EDEAE8",
                            borderRadius: "4px",
                          }}
                          InputLabelProps={{
                            style: { fontSize: 16, marginLeft: -4 },
                          }}
                          value={state.piece}
                          label="No of Cartons"
                          onChange={handlePieces}
                          error={errPiece}
                          helperText={
                            errPiece ? "Number of Cartons can not be blank" : ""
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <FormControl>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          size="small"
                          style={{
                            display: "inline-flex",
                            backgroundColor: "#EDEAE8",
                            borderRadius: "4px",
                          }}
                          InputLabelProps={{
                            style: { fontSize: 14, marginLeft: -6 },
                          }}
                          required
                          value={state.weight}
                          label="Total Weight [KG]"
                          onChange={handleWeight}
                          error={errWght}
                          helperText={
                            errWght ? "Weight must be grater than 0" : ""
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <FormControl>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          fullWidth
                          size="small"
                          style={{
                            display: "inline-flex",
                            backgroundColor: "#EDEAE8",
                            borderRadius: "4px",
                          }}
                          value={state.volume}
                          label="Total Volume[CBM]"
                          onChange={handleVolume}
                          error={errVolm}
                          helperText={errVolm ? "Volume can not be blank" : ""}
                          InputLabelProps={{
                            style: {
                              fontSize: 14,
                              marginLeft: -6,
                              marginRight: -16,
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        fullWidth
                        size="small"
                        style={{
                          display: "inline-flex",
                          backgroundColor: "#EDEAE8",
                          borderRadius: "4px",
                        }}
                        required
                        value={state.blnumber}
                        label="BL Number"
                        onChange={handleBlNo}
                        error={errBlNo}
                        helperText={errBlNo ? "BL number can not be blank" : ""}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        fullWidth
                        size="small"
                        style={{
                          display: "inline-flex",
                          backgroundColor: "#EDEAE8",
                          borderRadius: "4px",
                        }}
                        value={state.vessel}
                        label="Vessel"
                        onChange={handlevessel}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="gem-origin-select"
                        size="small"
                        disableClearable
                        options={availableAllOriginDestinations}
                        getOptionLabel={(option) =>
                          option.City +
                          ", " +
                          option.StateProvince +
                          ", " +
                          option.Country +
                          "- [" +
                          option.UNLoCode +
                          "]"
                        }
                        renderOption={(option) => (
                          <React.Fragment>
                            {option.City +
                              ", " +
                              option.StateProvince +
                              ", " +
                              option.Country +
                              "- [" +
                              option.UNLoCode +
                              "]"}
                          </React.Fragment>
                        )}
                        style={{
                          display: "inline-flex",
                          backgroundColor: "#f3edea",
                        }}
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Origin"
                            variant="outlined"
                            required
                            error={errOrgn}
                            helperText={
                              errOrgn ? "Origin can not be blank" : ""
                            }
                          />
                        )}
                        fullWidth
                        onChange={onSelectOrigine}
                        color="primay"
                        value={state.selectedOrigin}
                        inputValue={state.originValue}
                        onInputChange={handleOriginValueChange}
                        classes={{ option: classes.option }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <Autocomplete
                        id="gem-transMode-select"
                        size="small"
                        fullWidth
                        options={availableTRansMode}
                        getOptionLabel={(option) => option.view}
                        renderOption={(option) => (
                          <React.Fragment>{option.view}</React.Fragment>
                        )}
                        value={state.selectedTrnsModeORGN}
                        style={{
                          display: "inline-flex",
                          backgroundColor: "#f3edea",
                        }}
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Transportaion Mode"
                            variant="outlined"
                          />
                        )}
                        // onChange={onSelectCarrier}
                        onChange={(event, nwValue) => {
                          setState({
                            ...state,
                            selectedTrnsModeORGN: nwValue,
                          });
                        }}
                        color="primay"
                        classes={{ option: classes.option }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          id="date-pol"
                          label="POL Date"
                          format="MM/dd/yyyy"
                          value={state.selectedPOLDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          disableFuture={true}
                          style={{
                            marginTop: -12,
                            paddingTop: 2,
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>

                  {/* visibility off for the time being */}
                  <Grid container spacing={3}>
                    {(state.slectedBLType?.type === "Door-CY" ||
                      state.slectedBLType?.type === "Door-Door" ||
                      state.slectedBLType?.type === "Door-CY/Ramp") && (
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Autocomplete
                          id="gem-LoadPort-select"
                          size="small"
                          options={availableAllOriginDestinations}
                          getOptionLabel={(option) =>
                            option.City +
                            ", " +
                            option.StateProvince +
                            ", " +
                            option.Country +
                            "- [" +
                            option.UNLoCode +
                            "]"
                          }
                          renderOption={(option) => (
                            <React.Fragment>
                              {option.City +
                                ", " +
                                option.StateProvince +
                                ", " +
                                option.Country +
                                "- [" +
                                option.UNLoCode +
                                "]"}
                            </React.Fragment>
                          )}
                          style={{
                            display: "inline-flex",
                            backgroundColor: "#f3edea",
                          }}
                          autoSelect={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Load Port"
                              variant="outlined"
                              required
                              error={errLoadPrt}
                              helperText={
                                errLoadPrt
                                  ? "Load Port can not be blank for this Shipment type"
                                  : ""
                              }
                            />
                          )}
                          fullWidth
                          disableClearable
                          onChange={onSelectLoadPort}
                          color="primay"
                          value={state.selectedLoadPort}
                          inputValue={state.loadPortValue}
                          onInputChange={handleLoadPortValueChange}
                          classes={{ option: classes.option }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={transshipments}
                            onChange={handleChangeTransshipment}
                            name="Transshipment"
                          />
                        }
                        label="Transshipment"
                      />
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        disabled={!transshipments}
                        value={state.transShipvessel}
                        label="Transship Vessel Name"
                        onChange={handletransShipvessel}
                      />
                      <Autocomplete
                        id="gem-transshipmentPort-select"
                        size="small"
                        options={availableAllOriginDestinations}
                        getOptionLabel={(option) =>
                          option.City +
                          ", " +
                          option.StateProvince +
                          ", " +
                          option.Country +
                          "- [" +
                          option.UNLoCode +
                          "]"
                        }
                        fullWidth
                        disableClearable
                        renderOption={(option) => (
                          <React.Fragment>
                            {option.City +
                              ", " +
                              option.StateProvince +
                              ", " +
                              option.Country +
                              "- [" +
                              option.UNLoCode +
                              "]"}
                          </React.Fragment>
                        )}
                        style={{
                          display: "inline-flex",
                          backgroundColor: "#f3edea",
                        }}
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Transshipment Port"
                            variant="outlined"
                          />
                        )}
                        disabled={!transshipments}
                        onChange={onSelectTransShpPort}
                        color="primay"
                        value={state.selectedTransShpPort}
                        inputValue={state.transShpPortValue}
                        onInputChange={handleTransShpPortValueChange}
                        classes={{ option: classes.option }}
                      />

                      {/* <Autocomplete
                              id="gem-transMode-select"
                              size="small"
                              fullWidth
                              disableClearable
                              options={availableTRansMode}
                              getOptionLabel={(option) => option.mode}
                              renderOption={(option) => (
                                <React.Fragment>{option.mode}</React.Fragment>
                              )}
                              value={state.selectedTrnsModeLOAD}
                              style={{
                                display: "inline-flex",
                                backgroundColor: "#f3edea",
                              }}
                              autoSelect={true}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Transportaion Mode"
                                  variant="outlined"
                                />
                              )}
                              
                              onChange={(event, nwValue) => {
                                setState({ ...state, selectedTrnsModeLOAD: nwValue });
                              }}
                              color="primay"
                              classes={{ option: classes.option }}
                            /> */}
                    </Grid>
                  </Grid>

                  {(state.slectedBLType?.type === "CY-Door" ||
                    state.slectedBLType?.type === "Door-Door") && (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="gem-discharg-select"
                            size="small"
                            options={availableAllOriginDestinations}
                            getOptionLabel={(option) =>
                              option.City +
                              ", " +
                              option.StateProvince +
                              ", " +
                              option.Country +
                              "- [" +
                              option.UNLoCode +
                              "]"
                            }
                            fullWidth
                            disableClearable
                            renderOption={(option) => (
                              <React.Fragment>
                                {option.City +
                                  ", " +
                                  option.StateProvince +
                                  ", " +
                                  option.Country +
                                  "- [" +
                                  option.UNLoCode +
                                  "]"}
                              </React.Fragment>
                            )}
                            style={{
                              display: "inline-flex",
                              backgroundColor: "#f3edea",
                            }}
                            autoSelect={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Discharge Port"
                                variant="outlined"
                              />
                            )}
                            onChange={onSelectDiscPort}
                            color="primay"
                            value={state.selectedDiscPort}
                            inputValue={state.discPortValue}
                            onInputChange={handleDiscPortValueChange}
                            classes={{ option: classes.option }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="gem-transMode-select"
                            size="small"
                            fullWidth
                            disableClearable
                            options={availableTRansMode}
                            getOptionLabel={(option) => option.view}
                            renderOption={(option) => (
                              <React.Fragment>{option.view}</React.Fragment>
                            )}
                            value={state.selectedTrnsModeDISC}
                            style={{
                              display: "inline-flex",
                              backgroundColor: "#f3edea",
                            }}
                            autoSelect={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Transportaion Mode"
                                variant="outlined"
                              />
                            )}
                            onChange={(event, nwValue) => {
                              setState({
                                ...state,
                                selectedTrnsModeDISC: nwValue,
                              });
                            }}
                            color="primay"
                            classes={{ option: classes.option }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="gem-cyRamp-select"
                            size="small"
                            options={availableAllOriginDestinations}
                            getOptionLabel={(option) =>
                              option.City +
                              ", " +
                              option.StateProvince +
                              ", " +
                              option.Country +
                              "- [" +
                              option.UNLoCode +
                              "]"
                            }
                            renderOption={(option) => (
                              <React.Fragment>
                                {option.City +
                                  ", " +
                                  option.StateProvince +
                                  ", " +
                                  option.Country +
                                  "- [" +
                                  option.UNLoCode +
                                  "]"}
                              </React.Fragment>
                            )}
                            style={{
                              display: "inline-flex",
                              backgroundColor: "#f3edea",
                            }}
                            autoSelect={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Inland CY/Ramp"
                                variant="outlined"
                              />
                            )}
                            fullWidth
                            disableClearable
                            onChange={onSelectCYRamp}
                            color="primay"
                            value={state.selectedCyRamp}
                            inputValue={state.cyRampValue}
                            onInputChange={handleCyRampValueChange}
                            classes={{ option: classes.option }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="gem-transMode-select"
                            size="small"
                            fullWidth
                            options={availableTRansMode}
                            getOptionLabel={(option) => option.view}
                            renderOption={(option) => (
                              <React.Fragment>{option.view}</React.Fragment>
                            )}
                            value={state.selectedTrnsModeCYRAMP}
                            style={{
                              display: "inline-flex",
                              backgroundColor: "#f3edea",
                            }}
                            autoSelect={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Transportaion Mode"
                                variant="outlined"
                              />
                            )}
                            // onChange={onSelectCarrier}
                            onChange={(event, nwValue) => {
                              setState({
                                ...state,
                                selectedTrnsModeCYRAMP: nwValue,
                              });
                            }}
                            color="primay"
                            classes={{ option: classes.option }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {(state.slectedBLType?.type === "CY-CY/Ramp" ||
                    state.slectedBLType?.type === "Door-CY/Ramp") && (
                    <>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="gem-discharg-select"
                            size="small"
                            options={availableAllOriginDestinations}
                            getOptionLabel={(option) =>
                              option.City +
                              ", " +
                              option.StateProvince +
                              ", " +
                              option.Country +
                              "- [" +
                              option.UNLoCode +
                              "]"
                            }
                            fullWidth
                            disableClearable
                            renderOption={(option) => (
                              <React.Fragment>
                                {option.City +
                                  ", " +
                                  option.StateProvince +
                                  ", " +
                                  option.Country +
                                  "- [" +
                                  option.UNLoCode +
                                  "]"}
                              </React.Fragment>
                            )}
                            style={{
                              display: "inline-flex",
                              backgroundColor: "#f3edea",
                            }}
                            autoSelect={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Discharge Port"
                                variant="outlined"
                                required
                                error={errDiscPrt}
                                helperText={
                                  errDiscPrt
                                    ? "Discharge Port can not be blank for this Shipment type"
                                    : ""
                                }
                              />
                            )}
                            onChange={onSelectDiscPort}
                            color="primay"
                            value={state.selectedDiscPort}
                            inputValue={state.discPortValue}
                            onInputChange={handleDiscPortValueChange}
                            classes={{ option: classes.option }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Autocomplete
                            id="gem-transMode-select"
                            size="small"
                            fullWidth
                            disableClearable
                            options={availableTRansMode}
                            getOptionLabel={(option) => option.view}
                            renderOption={(option) => (
                              <React.Fragment>{option.view}</React.Fragment>
                            )}
                            value={state.selectedTrnsModeDISC}
                            style={{
                              display: "inline-flex",
                              backgroundColor: "#f3edea",
                            }}
                            autoSelect={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Transportaion Mode"
                                variant="outlined"
                              />
                            )}
                            onChange={(event, nwValue) => {
                              setState({
                                ...state,
                                selectedTrnsModeDISC: nwValue,
                              });
                            }}
                            color="primay"
                            classes={{ option: classes.option }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {/* visibility off for the time being */}

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Autocomplete
                        id="gem-FinalDestination-select"
                        size="small"
                        options={availableAllOriginDestinations}
                        getOptionLabel={(option) =>
                          option.City +
                          ", " +
                          option.StateProvince +
                          ", " +
                          option.Country +
                          "- [" +
                          option.UNLoCode +
                          "]"
                        }
                        renderOption={(option) => (
                          <React.Fragment>
                            {option.City +
                              ", " +
                              option.StateProvince +
                              ", " +
                              option.Country +
                              "- [" +
                              option.UNLoCode +
                              "]"}
                          </React.Fragment>
                        )}
                        style={{
                          display: "inline-flex",
                          backgroundColor: "#f3edea",
                        }}
                        autoSelect={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Final Destination"
                            variant="outlined"
                            required
                            error={errDest}
                            helperText={
                              errDest ? "Destination can not be blank" : ""
                            }
                          />
                        )}
                        fullWidth
                        disableClearable
                        onChange={onSelectDestination}
                        color="primay"
                        value={state.selectedDestination}
                        inputValue={state.destinationValue}
                        onInputChange={handleDestinationValueChange}
                        classes={{ option: classes.option }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}></Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        name="comment1"
                        value={state.comment1}
                        label="Comment 1"
                        onChange={handlecomment}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        name="comment2"
                        value={state.comment2}
                        label="Comment 2"
                        onChange={handlecomment}
                        fullWidth
                      />
                    </Grid>
                    * mandatory fields
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        gap: 10,
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: "auto" }}
                        onClick={handleExpModalClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={addShipment}
                      >
                        {state.addShipmentAction + " Shipment"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Modal>
        </>
        <Dialog
          open={dialogViewShipmentOpen}
          onClose={handleViweShipmentDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"lg"}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "20px",
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {"Shipment Details - LCL"}
            </DialogTitle>
            <CloseButton onClose={handleViweShipmentDialogClose} />
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Card style={{ marginBottom: 15 }}>
                <CardContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    overflowX: "auto",
                  }}
                >
                  {/* <Typography variant="h6">Shipment Details:</Typography> */}

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <span>Container#: </span>
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.ContainerNo}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Container Size:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.ContainerSize}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Insert Date:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {}
                        {Moment(
                          selectedRows[0]?.InsertDate.split("T")[0]
                        ).format("MM/DD/YYYY")}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      BL No:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.BLNo}
                      </span>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Carrier:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.Carrier}
                      </span>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Vessel:{" "}
                      <span className={`${classes.textdata}`}>
                        {selectedRows[0]?.Vessel
                          ? selectedRows[0]?.Vessel
                          : // "/" +
                            // selectedRows[0]?.Voyage
                            ""}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Origin:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.Origin}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Load Port:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.LoadPort}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Transshipment Port:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.TransPort}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Transshipment Vessel:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.TranshipVesselName}
                      </span>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Discharge Port:
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.DischargePort}
                      </span>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Inland CY/Ramp:{" "}
                      <span className={`${classes.textdata}`}>
                        {selectedRows[0]?.InlandCYRamp}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Destination:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.Destination}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Transportation Mode:{" "}
                      <span className={`${classes.textdata}`}>
                        {selectedRows[0]?.OriginToLoadPortTranMode !== ""
                          ? selectedRows[0]?.OriginToLoadPortTranMode
                          : "Sea"}
                        {selectedRows[0]?.LoadPortLocCode !== ""
                          ? "-" + selectedRows[0]?.LoadPortToTransPortTranMode
                          : ""}
                        {selectedRows[0]?.TransPortLocCode !== ""
                          ? "-" +
                            selectedRows[0]?.TransPortToDischargePortTranMode
                          : "Sea"}
                        {selectedRows[0]?.DischargePortToInlandRampTranMode !==
                        ""
                          ? "-" +
                            selectedRows[0]?.DischargePortToInlandRampTranMode
                          : ""}
                        {selectedRows[0]?.InlandCYRampCode !== ""
                          ? "-" + selectedRows[0]?.InlandRampToDestTranMode
                          : ""}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Total CO<sub>2</sub>e:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.CO2Emission_in_grams}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Emission Calc Based on:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.Emission_Cacl_Basis}
                      </span>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Emission calc Date:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {Moment(
                          selectedRows[0]?.InsertDate.split("T")[0]
                        ).format("MM/DD/YYYY")}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      No of Cartons:{" "}
                      <span className={`${classes.textdata}`}>
                        {selectedRows[0]?.NoOfCartons}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Total Weight [KG]:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.TotalWeight}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      Total Volume [CBM]:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.TotalVolume}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      Comment1:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.Comment1}
                      </span>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      Comment2:{" "}
                      <span className={`${classes.textdata}`}>
                        {" "}
                        {selectedRows[0]?.Comment2}
                      </span>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Card>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {succMsg}
        </Alert>
      </Snackbar>
      <Dialog
        fullScreen={fullScreen}
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Please confirm Delete action"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{deleteConfirmText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => DeleteAllSelected(statQuo)}
            color="primary"
            autoFocus
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LCLShipments;
